import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import CreditDetails from "../Details/CreditDetails";
import { toTitleCase } from '../../common/utils';

class Credits extends Component {
    render() {
        return <Box className="itemBox">
                <Typography variant="h6" component="h4" className="text-center" >
                        Credits
                </Typography>
                <CreditDetails
                    data={this.props.programDetails?.TOTAL_CREDITS_EARNED || 0}
                    text="Hours Completed"
                />
                <CreditDetails
                    data={this.props.totalTransferredCredits || 0}
                    text="Hours Transferred"
                />
                <CreditDetails
                    data={
                        this.props.programDetails?.ENROLLED > 0
                            ? this.props.programDetails.ENROLLED
                            : 0
                    }
                    text="Hours In Progress"
                />
                <CreditDetails
                    data={this.props.programDetails?.PLANNED || 0}
                    text={
                        (this.props.nextSem?.TERM_CODE &&
                            'Registered for ' +
                                toTitleCase(this.props.nextTerm)) ||
                        'Hours Registered'
                    }
                />
            </Box>
    }
}

export default Credits;