import React, {Component} from 'react';
import {Grid, Typography, Skeleton, List, ListItem, Box} from '@mui/material';
import Widget from '../common/Widget';
import ContactCard from '../common/ContactCard';
import {getData} from '../../DataAccessLayer';
import {myAdvisingTeam} from '../../DataAccessLayer/services';
import ApiErrorMessage from '../common/ApiErrorMessage';

class MyAdvisingTeam extends Component {
    state = {
        advisors: [],
        isExpand: true,
        menu: null,
        // [{id: 'myAdvisingTeam_hide', title: 'Hide'}]
        isError: false,
        isLoading: false
    };

    componentDidMount() {
        this.loadAdvisingTeam();
    }

    loadAdvisingTeam = async () => {
        this.setState({isLoading: true, isError: false});
        try {
            let data = await getData(myAdvisingTeam, true);
            data = data.reduce((prevAdvisors, currentAdvisor) => {
                const firstName = currentAdvisor.firstName
                    ? currentAdvisor.firstName
                    : currentAdvisor.NAME?.split(' ')[0];
                const lastName = currentAdvisor.lastName
                    ? currentAdvisor.lastName
                    : currentAdvisor.NAME?.split(' ')[1];
                if (
                    currentAdvisor.MIDAS_ID &&
                    firstName?.trim() &&
                    lastName?.trim()
                ) {
                    currentAdvisor.firstName = firstName;
                    currentAdvisor.lastName = lastName;
                    prevAdvisors.push(currentAdvisor);
                }
                return prevAdvisors;
            }, []);
            this.setState({
                advisors: data
            });
        } catch (err) {
            this.setState({isError: true});
        } finally {
            this.setState({isLoading: false});
        }
    };

    reorderName = name => {
        const [firstName, lastName] = name.split(' ');

        return `${lastName}, ${firstName}`;
    };

    render() {
        const {widgetId, menu, advisors, isLoading, isError} = this.state;
        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        menu: menu,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    {...this.props}
                    hasScrollY
                    className="myOdu__myAdvisingTeam"
                >
                    {/* <Grid
                        container
                        alignItems={'stretch'}
                        sx={{p: 0}}
                        spacing={1}
                    > */}
                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="scrollWrapper"
                    >
                        {isLoading ? (
                            <Skeleton variant="rectangular" height={80} />
                        ) : advisors.length === 0 && !isLoading ? (
                            isError ? (
                                <ApiErrorMessage
                                    widgetName={this.props.title}
                                    reload={this.loadAdvisingTeam}
                                />
                            ) : (
                                <Box className="noAdvisorsFound">
                                    No advisors found.
                                </Box>
                            )
                        ) : (
                            <List
                                spacing={0}
                                className="myOdu__contactCardList"
                                disableGutters
                            >
                                {advisors.map(advisor => {
                                    const contact = {
                                        id: advisor.MIDAS_ID,
                                        name: advisor.NAME,
                                        firstName: advisor.firstName,
                                        lastName: advisor.lastName,
                                        email: advisor.EMAIL_ID,
                                        department: advisor.department,
                                        phoneNumber: advisor.phoneNumber,
                                        img: advisor.img,
                                        directoryLink: advisor.directoryLink
                                    };

                                    return (
                                        <ListItem key={contact.id}>
                                            <ContactCard data={contact} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </Grid>
                    {/* </Grid> */}
                </Widget>
            </>
        );
    }
}

export default MyAdvisingTeam;
