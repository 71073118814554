import {Box, LinearProgress, Stack, Tooltip, Typography} from '@mui/material';
import React, {Component} from 'react';
import ActionButton from './ActionButton';
import PortalDataGridPro from '../common/PortalDataGridPro';
import {GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid';
import {WithSmallScreen} from '../common/WithSmallScreen';

class StudentDetails extends Component {
    state = {
        pinned: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'LASTNAME'],
            right: []
        }
    };

    //θ(1) chnages the pinned columns
    onPinnedChange = pinned => {
        this.setState({
            pinned
        });
    };

    render() {
        const {id} = this.props;
        return (
            <React.Fragment>
                <div id={`${id}__div_details`} className="myOdu__resultBox">
                    {
                        <Stack
                            id={`${id}__stack_details`}
                            direction={'column'}
                            gap={2}
                        >
                            {this.props.message && (
                                <Typography
                                    id={`${id}__text_advisor`}
                                    className=""
                                    component="h3"
                                >
                                    {this.props.message}
                                </Typography>
                            )}
                            <ActionButton
                                id={`${id}`}
                                actionMenuItems={this.props.actionMenuItems}
                                disableActionButton={
                                    this.props.disableActionButton
                                }
                            />
                            <PortalDataGridPro
                                className="myOdu__resultTable"
                                id={`${id}__datagrid`}
                                students={this.props.students}
                                columns={this.props.columns}
                                rows={this.props.students}
                                getRowId={row => {
                                    let id = row.SPRIDEN_PIDM;
                                    if (row.SGRADVR_ADVR_PIDM) {
                                        id = `${id}_${row.SGRADVR_ADVR_PIDM}`;
                                    }
                                    return id;
                                }}
                                checkboxSelection={
                                    this.props.isHideCheckbox ? false : true
                                }
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={
                                    this.props.onStudentSelected
                                }
                                isLoading={this.props.isLoading}
                                slots={{
                                    ...this.props.slots,
                                    loadingOverlay: LinearProgress,
                                    noRowsOverlay: () => {
                                        return (
                                            <Box
                                                sx={{
                                                    margin: 1
                                                }}
                                            >
                                                <Typography
                                                    variant="p"
                                                    component="p"
                                                >
                                                    {
                                                        this.props
                                                            .noStudentMessage
                                                    }
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                }}
                                autoHeight
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            {field: 'LASTNAME', sort: 'asc'}
                                        ]
                                    },
                                    pagination: {
                                        paginationModel: {pageSize: 20}
                                    }
                                }}
                                pagination
                                pinnedColumns={
                                    this.props.isSmall ||
                                    this.props.isMD ||
                                    this.props.isLG
                                        ? {}
                                        : this.state.pinned
                                }
                                onPinnedColumnsChange={this.onPinnedChange}
                            />
                        </Stack>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default WithSmallScreen(StudentDetails);
