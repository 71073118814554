import { Box, Button, Typography, Snackbar, Stack, Switch } from "@mui/material";
import React, {Component} from "react";
import Section from "./Section";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { savePreferences } from "../../../SetUp/actions";
import { WidgetConfigs } from "../../../SetUp/config";
import { getData, putData } from "../../../../DataAccessLayer";
import { myPreferences } from "../../../../DataAccessLayer/services";
import { SectionParams } from "../../../Dashboard/config";
import { MOVE_WIDGET_DOWN, MOVE_WIDGET_UP } from "../../../common/constants";
import { toast } from 'react-toastify';
import { getUser } from "../../../Layout/utils";
import { getDefaultWidgetOrder } from "../../../SetUp/utils";
import { arrayMove, arraySwap } from "@dnd-kit/sortable";
import { deepCloneObject, swap } from "../../../common/utils";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import { getWidgets } from "../../../Dashboard/utils";

class ReorderWidgets extends Component {

    state = {
        selectedSection: -1,
        isDNDSelected: true,
        widgets: []
    }

    componentDidMount() {
        this.updateWidgets();
    }

    //θ(N) wher N is the number of widgets
    //Maps all the widget with details from preferences object and widget configs.
    updateWidgets = () => {
        const preferences = this.props.preferences;

        //Get details of each widget from every section
        const widgets = preferences?.Widgets?.WidgetsOrder?.map((section, index) => {
            return getWidgets(section, index, true);
        })

        this.setState({
            widgets
        })
    }

    //Θ(1) toggle isDNDSelected
    toggleIsDNDSelected = (isDNDSelected = !this.state.isDNDSelected) => {
        this.setState({
            isDNDSelected
        })
    }

    //Θ(N) where N is the number of widgets in the section
    //Moves the widget 1 position up or down as per the suggested direction
    moveWidget = (widgetId, sectionId, direction) => {

        let widgetsSection = this.state.widgets.length ? this.state.widgets[sectionId] : getDefaultWidgetOrder();
        
        //Find the index of the widget in the section which has to be moved
        let index = widgetsSection.findIndex(orderedWidget => orderedWidget.id === widgetId);

        //If widget has to be moved up by 1 position
        if(direction === MOVE_WIDGET_UP && index > 0) {
            //Swap the widget with index-1
            swap(widgetsSection, index, index - 1);
            
        } 
        //If widget needs to be moved down by one position.
        else if(direction === MOVE_WIDGET_DOWN && index < widgetsSection.length -1) {
            //Swap the widget with widget at position idex+1
            swap(widgetsSection, index, index + 1);
        }
        
        let widgets = this.state.widgets;
        widgets[sectionId] = widgetsSection;

        this.setState({
            widgets
        })
    }

    //Θ(1) Sets the section number of the widget being moved
    onDragStart = (result) => {
        this.setState({
            selectedSection: result.active.data.current.section
        })
    }

    //Θ(N) where N is the number of widgets in section
    //Change the indexes of the widgets when drag ends
    onDragEnd = (result) => {
        this.setState({
            selectedSection: -1
        })
        //Get active and over from result which is an event generated by dnd kit
        const {active, over} = result;
        if(active?.id && over?.id) {
            //Get the section number from data stored in dnd kit.
            //Note: active.data.current is the structure set by dnd kit
            const sectionNumber = active?.data?.current?.section;
            // let widgetsOrder = this.state?.preferences?.Widgets?.WidgetsOrder[sectionNumber];
            let widgetSection = this.state.widgets[sectionNumber];

            const oldIndex = widgetSection.findIndex((item) => item.id === active.id);
            const newIndex = widgetSection.findIndex((item) => item.id === over.id);

            widgetSection = arrayMove(widgetSection, oldIndex, newIndex);

            let widgets = this.state.widgets;
            widgets[sectionNumber] = widgetSection;

            this.setState({
                widgets
            })
        }
    }

    //θ(1) Returns the widget object which has to be stored into preferences
    getPreferenceWidget = (widget) => {
        return {
            id: widget.id,
            isDisplay: widget.isDisplay,
            isExpand: widget.isExpand
        }
    }

    //Θ(1) Saves the preferences on database
    onSaveWidgets = () => {
        let preferences = deepClone(this.props.preferences);

        //Create array of objects of widgets what we have to store into preferences
        let widgetsOrder = this.state.widgets.map(section => {
            return section.map(widgetDetails => {
                return this.getPreferenceWidget(widgetDetails);
            })
        })

        //Assign updated widgets order
        preferences.Widgets.WidgetsOrder = widgetsOrder;

        putData(myPreferences, {preferences, midas: this.props.isImpersonating ? this.props.impersonation.midas : this.props.user.midas})
        .then(result => {
            this.props.savePreferences(preferences);
            if(result)
                toast.success('Save was successful')
            else
                toast.error('Could not save at this time.');
            this.loadPreferences();
        })
        .catch(err => {
            console.log(err)
            toast.error('Could not save at this time.')
        })
    }

    //Θ(1) Loads users preferences
    loadPreferences = () => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas),
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
            this.updateWidgets();
        })
        .catch(err => {
            console.log(err);
        });
    }

    //Θ(1) Cancels and reorders to the actual settings
    onCancel = () => {
        this.updateWidgets();
    }

    render() {
        // const widgetOrder = this.state?.preferences?.Widgets?.WidgetsOrder || getDefaultWidgetOrder();

        return <Box className="setDisplayOrder">
            <Box className="widgetReorder">
                <Typography component='h3' className="sr-only visibility-hidden">Widgets - Set Display Order</Typography>
                <Snackbar 
                    open = {this.state.selectedSection > 0}
                    message = {'Widgets may be dragged and dropped within their section'}
                />
                <Row>
                    <Box sx={{width: '100%'}}>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            className="myOdu__toggleSwitch"
                        >
                            <Stack direction={{sm: 'column', md: 'row'}}>
                                <label id = 'modeLabel'>   
                                        Choose mode: &nbsp;
                                </label>
                                
                                <Stack direction="row">
                                    <Typography>
                                        Drag and Drop
                                    </Typography>
                                        <Switch 
                                            id = "settings_widgets_redorder_switch_toggleDND"
                                            size="small"
                                            color='primary'
                                            value={this.state.isDNDSelected}
                                            onChange={() => {this.toggleIsDNDSelected()}}
                                            inputProps={{
                                                "aria-labelledby": 'modeLabel'
                                            }}
                                        />
                                    <Typography>
                                        Keyboard Accessible
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Row>
                <Row className="pt-3">
                    {
                        this.state.widgets.map((widgetOrder, idx) => {
                            return <Col>
                                <Section 
                                    sectionNumber = {idx}
                                    selectedSection = {this.state.selectedSection}
                                    isDroppable = {Number(this.state.selectedSection) > -1 && Number(this.state.selectedSection) !== (idx)}
                                    isDNDSelected = {this.state.isDNDSelected}
                                    widgetsOrder = {widgetOrder}
                                    moveWidget = {this.moveWidget}
                                    onDragEnd = {this.onDragEnd}
                                    onDragStart = {this.onDragStart}
                                />
                            </Col>
                        })
                    }
                </Row>
                <Row style={{ marginTop: 8}}>
                    <Stack
                        sx={{width: '100%', mt: 2}}
                        direction={{xs: 'col', sm: 'row'}}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                    >                   
                        <Button style={{margin: 2}} variant='outlined' className="myOdu__button primary" onClick={this.onSaveWidgets}>Save Display Order</Button>
                        <Button style={{margin: 2}} variant='outlined' className="myOdu__button secondary" onClick={this.onCancel}>Cancel</Button>
                    </Stack>
                </Row>
            </Box>
        </Box>
    }
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReorderWidgets)