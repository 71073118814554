export const DataColumns = [
    { 
        field: "id", 
        headerName: "ID",
        width: 90 
    },
    {
        field: "firstName",
        headerName: "First name",
        width: 150,
    },
    {
        field: "lastName",
        headerName: "Last name",
        width: 150,
    },
    {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (params, row) => `${row.firstName || ""} ${row.lastName || ""}`,
    },
];

export const DataRows = [
    { id: 1, lastName: "Snow", firstName: "Jon" },
    { id: 2, lastName: "Lannister", firstName: "Cersei" },
    { id: 3, lastName: "Lannister", firstName: "Jaime" },
    { id: 4, lastName: "Stark", firstName: "Arya" },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null },
    { id: 7, lastName: "Clifford", firstName: "Ferrara" },
    { id: 8, lastName: "Frances", firstName: "Rossini" },
    { id: 9, lastName: "Roxie", firstName: "Harvey" },
    { id: 10, lastName: "LastName", firstName: "FirstName" },
    { id: 11, lastName: "Snow", firstName: "Jon" },
    { id: 12, lastName: "Lannister", firstName: "Cersei" },
    { id: 13, lastName: "Lannister", firstName: "Jaime" },
    { id: 14, lastName: "Stark", firstName: "Arya" },
    { id: 15, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 16, lastName: "Melisandre", firstName: null },
    { id: 17, lastName: "Clifford", firstName: "Ferrara" },
    { id: 18, lastName: "Frances", firstName: "Rossini" },
    { id: 19, lastName: "Roxie", firstName: "Harvey" },
    { id: 21, lastName: "Snow", firstName: "Jon" },
    { id: 22, lastName: "Lannister", firstName: "Cersei" },
    { id: 23, lastName: "Lannister", firstName: "Jaime" },
    { id: 24, lastName: "Stark", firstName: "Arya" },
    { id: 25, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 26, lastName: "Melisandre", firstName: null },
    { id: 27, lastName: "Clifford", firstName: "Ferrara" },
    { id: 28, lastName: "Frances", firstName: "Rossini" },
    { id: 29, lastName: "Roxie", firstName: "Harvey" },
];