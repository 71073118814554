import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, {Component} from 'react';
import { Col, Row } from 'reactstrap';
import { DefaultDepartment } from '../config';
import { connect } from 'react-redux';
import PortalModal from '../../common/PortalModal';
import SAMFilter from '../SAMFilter';

class AssignAdvisorDialog extends Component {

    state = {
        id: 'assignAdvisor'
    };

    assignAdvisor = (selectedValues) => {
        //TODO: Assign new advisor to selected unassigned students
        this.props.onAssignAdvisor(selectedValues)
    }


    render() {
        const {id} = this.state;
        const {isAssignAdvisor, disablePrimaryButton} = this.props;

        return <React.Fragment>
            <PortalModal
                id={`${id}__dialog`}
                title = {'Assign New Advisor'}
                maxWidth='md'
                isOpen = {isAssignAdvisor}
                onClose = {() => {this.props.toggleIsAssignAdvisor()}}
                isCloseable
            >
                <SAMFilter
                    id = {id}
                    isDepartmentCode
                    isAllDepartment
                    departmentsLength = {{xs: 12, xl: 6}}

                    isAdvisorName
                    isAdvisorRequired
                    advisorsLength = {{xs: 12, xl: 6}}

                    primaryButtonText = {'Assign'}
                    onPrimary = {this.assignAdvisor}
                    primaryButtonLength = {{xs: 12, xl: 12}}
                    disablePrimaryButton = {disablePrimaryButton}
                />
            </PortalModal>
        </React.Fragment>
    }

}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        departments: state.samReducer.departments,
        advisors: state.samReducer.advisors,
        advisorsByDept: state.samReducer.advisorsByDept,
        advisorTypes: state.samReducer.advisorTypes
    };
};

export default connect(mapStateToProps)(AssignAdvisorDialog);