import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from '@mui/material';
import {GridActionsCellItem} from '@mui/x-data-grid-pro';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import {cleanUpID} from "../../common/utils";


const getColumns = (actionFunction, id) => {
    return [
        {field: 'TITLE', headerName: 'Title', flex: 1},
        {
            field: 'locations',
            headerName: 'Location',
            flex: 2,
            renderCell: params => {
                // Creating a unique ID using CategoryID_PlaceID_Index
                const rowId = params.id + '_' + params.api.getAllRowIds().indexOf(params.id)
                return (
                    <div id={id + "__div_" + cleanUpID(rowId)}>
                        {params.row.URL && (
                            <Link mr={1} target="_blank" href={params.row.URL} id={id + "__link_location_"  + cleanUpID(rowId)}>
                                {params.row.URL}
                            </Link>
                        )}
                        {params.row.MAP_URL && (
                            <Link target="_blank" href={params.row.MAP_URL} id={id + "__link_mapUrl_" + cleanUpID(rowId)}>
                                <FontAwesomeIcon
                                    id={id + "__icon_map_" + cleanUpID(rowId)}
                                    fixedWidth
                                    size="lg"
                                    icon={faLocationDot}
                                />
                            </Link>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            type: 'actions',
            getActions: event => [
                <GridActionsCellItem
                    icon={<FontAwesomeIcon id={id+"__icon_delete_"+cleanUpID(event.row.PLACE_ID)} icon={faTrash} />}
                    label="Delete"
                    id={id+"__gridItem_delete_"+cleanUpID(event.row.PLACE_ID)}
                    onClick={() => actionFunction(event.row)}
                />
            ]
        }
    ];
};

export default getColumns;
