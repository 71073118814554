import { SAVE_ADVISORS, SAVE_ADVISORS_BY_DEPT, SAVE_ADVISOR_TYPES, SAVE_DEPARTMENTS } from "./actions";

const initialStateSAM = {
    advisorsByDept: {},
    advisors: [],
    departments: [],
    advisorTypes: []
}

//needs to persist across pages
export const samReducer = (state = initialStateSAM, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_DEPARTMENTS:  {
            return {...state, departments: [...payload.departments]};
        }
        case SAVE_ADVISORS: {
            return {...state, advisors: [...payload.advisors]}
        }
        case SAVE_ADVISORS_BY_DEPT: {
            return {...state, advisorsByDept: payload.advisorsByDept}
        }
        case SAVE_ADVISOR_TYPES: {
            return {...state, advisorTypes: [...payload.advisorTypes]}
        }
        default:
            return state;
    }
}