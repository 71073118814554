import React, {Component} from 'react';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  Typography,
  List,
  ListItem,
  IconButton,
  Skeleton,
  Box
} from '@mui/material';
import Alert from './Alert';
import {Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/pro-light-svg-icons';
import {ACTION_UNHIDE, ALERT_KEY_FINANCIAL_AID} from '../common/constants';

class HiddenAlerts extends Component {
  state = {};

  componentDidMount() {
    if (this.props.hiddenAlerts?.length === 0) {
      this.props.toggleIsShowHiddenAlerts();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.hiddenAlerts != this.props.hiddenAlerts &&
      this.props.hiddenAlerts?.length === 0
    ) {
      this.props.toggleIsShowHiddenAlerts();
    }
  }

  render() {
    return (
      //TODO: convert this to portal modal, it will take care of the 
      //styling on the dialog title
      <Dialog
        id={this.props.id+"__dialog_hiddenAlerts"}
        open
        fullWidth
        maxWidth="xl"
        className="myOdu__modal decorativeHeader"
        onClose={this.props.toggleIsShowHiddenAlerts}
      >
        <DialogTitle
          id={this.props.id+"__dialogTitle"}
        >
          <Row id={this.props.id+"__row_hiddenAlertsWrapper"}>
            <Col id={this.props.id+"__col_hiddenAlertsTitleWrapper"}>
              <Typography id={this.props.id+"__text_hiddenAlertsTitle"} component="h2">
                Hidden Alerts
              </Typography>
            </Col>
            <Col id={this.props.id+"__col_closeIconWrapper"} xs={1} style={{display: 'flex', justifyContent: 'right'}}>
              <IconButton
                size="small"
                onClick={this.props.toggleIsShowHiddenAlerts}
                 id={this.props.id+"__IconButton_close"}
              >
                <FontAwesomeIcon id={this.props.id+"__icon_close"} icon={faX} style={{color: '#ffffff'}} />
              </IconButton>
            </Col>
          </Row>
        </DialogTitle>
        <DialogContent id={this.props.id+"__dialogContent_wrapper"}>
          <List id={this.props.id+"__list_alertsList_reducer"} sx={{p: 0}}>
            {this.props.hiddenAlerts.length > 0 &&
              this.props.hiddenAlerts.map((item, index) => {
                return (
                  <ListItem sx={{pr: 0, pl: 0}} key={index} id={this.props.id+"__listItem_alertItem_"+index} divider>
                    {!this.props.isLoading ? (
                      <Alert
                        id={this.props.id+'__alert'}
                        item={item}
                        index={index}
                        alertKey={ALERT_KEY_FINANCIAL_AID}
                        action={ACTION_UNHIDE}
                        expand={true}
                        toggleAlertVisibility={this.props.toggleAlertVisibility}
                      />
                    ) : (
                      <Box id={this.props.id+"__hiddenAlert_box"+index} sx={{width: '100%'}}>
                        <Skeleton id={this.props.id+"__HiddenAlert_skeleton"+index} variant="rectangular" height={20} />
                      </Box>
                    )}
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

export default HiddenAlerts;
