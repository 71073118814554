import {Box, Button, Grid, Tab, Tabs} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import ScheduleMeeting from './ScheduleMeeting/index.js';
import {connect} from 'react-redux';
import {ZoomTabMapping} from './config.js';
import {getData} from '../../../../../DataAccessLayer/index.js';
import {cct, cctSync, terms} from '../../../../../DataAccessLayer/services.js';
import moment from 'moment';
import Meetings from './Meetings/index.js';
import {FormDropDown} from '../../../../common/Form/FormDropdown.js';
import {WithSmallScreen} from '../../../../common/WithSmallScreen.js';
import { isUserAdmin } from '../../../../Layout/utils.js';
import { toast } from 'react-toastify';
import { saveZoomMeetings } from '../../actions.js';
import { Time } from '../../../../common/MomentTime.js';
import PortalTooltip from '../../../../common/PortalTooltip.js';

class Zoom extends Component {
    state = {
        activeTab: 1,
        isLoading: false
    };

    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        });
    };

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({
            activeTab
        });
    };

    componentDidMount() {
        if (!this.props.terms[0]) {
            this.getTerms();
        }
    }

    async getTerms() {
        try {
            await getData(terms, true);
        } catch (err) {
            console.log(err);
        }
    }

    //Θ(N) where N is length of meetings
    //Loads all the meetings from backend and segragates past and current meetings
    loadMeetings = () => {
        this.toggleIsLoading(true);
        this.props.saveZoomMeetings({
            currentMeetings: [],
            pastMeetings: [],
            isClassMeetingsScheduled: false
        });
        let crns = this.props.course.CRNS.split(',');
        getData(
            cct + '/' + crns[0] + '/' + this.props.course.SSBSECT_TERM_CODE,
            true
        )
            .then(result => {
                let meetings = {
                    currentMeetings: [],
                    pastMeetings: [],
                    isClassMeetingsScheduled: false
                };

                meetings = result?.meetings?.reduce(
                    (meetingsObject, meeting) => {
                        if (
                            meeting.start_url &&
                            Time.University(meeting.start_time)
                                .add(meeting.duration, 'minutes')
                                .diff(Time.University(), 'minutes') < 0 &&
                            !(this.props.course.isTaking && meeting.hideStudent)
                        )
                            meetingsObject.pastMeetings.push(meeting);
                        else if (
                            !(this.props.course.isTaking && meeting.hideStudent)
                        )
                            meetingsObject.currentMeetings.push(meeting);
                        return meetingsObject;
                    },
                    meetings
                );

                meetings.isClassMeetingsScheduled = result.classCount > 0;

                this.props.saveZoomMeetings(meetings);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(_ => {
                this.toggleIsLoading(false);
            });
    };

    //θ(1) Sync zoom meeting
    syncZoomMeetings = () => {
        this.toggleIsLoading(true);
        const crns = this.props.course.CRNS
            ? this.props.course.CRNS
            : this.props.course.CRN;

        const termCode = this.props.course.SSBSECT_TERM_CODE;

        const crn = crns?.split(',')[0];

        const url = cctSync + '/' + crn + '/' + termCode;
        getData(url, true)
        .then(_ => {
            toast.success('Meetings synced successfully!')
        })
        .catch(err => {
            console.log(err);
            toast.error('Meetings not synced!');
        })
        .finally(_ => {
            this.loadMeetings();
            this.toggleIsLoading(false);
        })
    }

    render() {
        const isTeaching = this.props.course.isTeaching;
        const isTaking = this.props.course.isTaking;

        const isLastSemCourse =
            this.props.course.SSBSECT_TERM_CODE === this.props.terms[0]?.code;

        const tabsDetails = [
            {label: 'Current/Upcoming', value: ZoomTabMapping.currentMeeting},
            {label: 'Past Meetings', value: ZoomTabMapping.pastMeeting}
        ];

        const isAdmin = isUserAdmin(this.props.user)
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h4>Zoom Meetings</h4>
                    </Col>
                </Row>
                {(!this.props.isSmall && (
                    <Box
                        sx={{
                            width: '100%',
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}
                    >
                        {
                            <Grid
                                container
                                justifyContent={'space-between'}
                                spacing={1}
                            >
                                <Grid item xs={12} md={6}>
                                    {!this.props.isSmall && (
                                        <Tabs
                                            value={this.state.activeTab}
                                            onChange={this.onTabChange}
                                            aria-label="secondary tabs example"
                                        >
                                            {tabsDetails.map(details => {
                                                return (
                                                    <Tab
                                                        value={details.value}
                                                        label={details.label}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                    )}
                                </Grid>
                                <Grid item>
                                    {
                                        isAdmin &&
                                        <PortalTooltip title={'Sync Meetings with Zoom | Admins Only'}>
                                            <Button
                                                className="myOdu__button syncMeeting"
                                                size="small"
                                                variant="outlined"
                                                style={{marginBottom: 6, marginRight: 6}}
                                                onClick={this.syncZoomMeetings}
                                                disabled={!isAdmin || this.state.isLoading}
                                            >
                                                Sync
                                            </Button>
                                        </PortalTooltip>
                                    }
                                    {isTeaching &&
                                        !isTaking &&
                                        !isLastSemCourse && (
                                            <Button
                                                className="myOdu__button scheduleMeeting"
                                                size="small"
                                                variant="outlined"
                                                style={{marginBottom: 6}}
                                                onClick={() =>
                                                    this.props.toggleIsScheduleMeeting(
                                                        true
                                                    )
                                                }
                                                disabled = {this.state.isLoading}
                                            >
                                                Schedule Meeting
                                            </Button>
                                        )}
                                </Grid>
                            </Grid>
                        }
                    </Box>
                )) || (
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                width: '100%',
                                borderBottom: 1,
                                borderColor: 'divider'
                            }}
                        >
                            {isTeaching && !isTaking && !isLastSemCourse && (
                                <Button
                                    className="myOdu__button scheduleMeeting"
                                    size="small"
                                    variant="outlined"
                                    style={{marginBottom: 6}}
                                    onClick={() =>
                                        this.props.toggleIsScheduleMeeting(true)
                                    }
                                    fullWidth
                                >
                                    Schedule Meeting
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormDropDown
                                options={tabsDetails}
                                labelGridProps={{xs: 0, md: 0}}
                                inputGridProps={{xs: 12, md: 0}}
                                onChange={event =>
                                    this.onTabChange(_, event.target.value)
                                }
                                id="select-meetings-type"
                                value={this.state.activeTab}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                )}
                {this.state.activeTab == ZoomTabMapping.currentMeeting && (
                    <Meetings
                        isLastSemCourse={isLastSemCourse}
                        meetings={this.props.currentMeetings}
                        activeTab={this.state.activeTab}
                        onTabChange={this.onTabChange}
                        openClassSchedule={this.props.openClassSchedule}
                        openAdhocSchedule={this.props.openAdhocSchedule}
                        isClassMeetingsScheduled={
                            this.props.isClassMeetingsScheduled
                        }
                        loadMeetings = {this.loadMeetings}
                        toggleIsLoading = {this.toggleIsLoading}
                        isLoading = {this.state.isLoading}
                    />
                )}
                {this.state.activeTab == ZoomTabMapping.pastMeeting && (
                    <Meetings
                        isPastMeetings={true}
                        isLastSemCourse={isLastSemCourse}
                        meetings={this.props.pastMeetings}
                        activeTab={this.state.activeTab}
                        onTabChange={this.onTabChange}
                        openClassSchedule={this.props.openClassSchedule}
                        openAdhocSchedule={this.props.openAdhocSchedule}
                        isClassMeetingsScheduled={
                            this.props.isClassMeetingsScheduled
                        }
                        loadMeetings = {this.loadMeetings}
                        toggleIsLoading = {this.toggleIsLoading}
                        isLoading = {this.state.isLoading}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        course: state.CCTReducer.course,
        terms: state.termsReducer.terms,
        currentMeetings: state.CCTReducer.meetings.currentMeetings,
        pastMeetings: state.CCTReducer.meetings.pastMeetings,
        isClassMeetingsScheduled: state.CCTReducer.meetings.isClassMeetingsScheduled
    }
}

const mapDispatchToProps = dispatch => ({
    saveZoomMeetings: meetings => dispatch(saveZoomMeetings(meetings))
});
 
export default connect(mapStateToProps, mapDispatchToProps)(WithSmallScreen(Zoom));
