import React, {useState} from 'react';
import {LinearProgress, Paper} from '@mui/material';
import PortalDataGridPro from '../../common/PortalDataGridPro';

export const FrontendErrorLogTable = props => {
    return (
        <React.Fragment>
            <Paper sx={{width: '100%', overflow: 'hidden'}} elevation={0}>
                <PortalDataGridPro
                    rows={props.rows}
                    columns={props.columns}
                    handleClick={props.onClick}
                    isLoading={props.isLoading}
                    columnVisibilityModel={{
                        ...props.columnVisibilityModel,
                        createdAt: false
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10}
                        }
                    }}
                    getRowId={row => {
                        return row.id
                            ? row.id
                            : row.MIDAS.concat('_', row.CREATED_AT);
                    }}
                    slots={{
                        ...props.slots,
                        loadingOverlay: LinearProgress
                    }}
                    pagination
                    slotProps={props.slotProps}
                    disableColumnFilter={props.disableColumnFilter}
                    disableColumnSelector={props.disableColumnSelector}
                    disableDensitySelector={props.disableDensitySelector}
                />
            </Paper>
        </React.Fragment>
    );
};
