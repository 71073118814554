import React, {Component} from 'react';
import {connect} from 'react-redux';
import App from './App';
import {AppConfigs, AppsTabMapping} from './config';
import {
    Grid,
    Box,
    Button,
    Tabs,
    Tab,
    Stack,
    Typography,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import {saveSetup} from './actions';
import {Col, Container, Row} from 'reactstrap';
import TabPanel from '../common/TabPanel';
import PortalTooltip from '../common/PortalTooltip';
import {WithSmallScreen} from '../common/WithSmallScreen';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisVertical} from '@fortawesome/pro-light-svg-icons';
import {getUser, isUserMedical} from '../Layout/utils';

class Apps extends Component {
    state = {
        selectedTab: isUserMedical(
            getUser(this.props.user, this.props.impersonation)
        )
            ? 1
            : 0,
        anchor: null,
        isOpen: false,
        tooltipApp: undefined
    };

    updateTooltip = appName => {
        this.setState({
            tooltipApp: appName
        });
    };

    dropAnchor = e => {
        this.setState({anchor: e.currentTarget, isOpen: true});
    };

    pullAnchor = () => {
        this.setState({anchor: null, isOpen: false});
    };

    setSelectedTab = (event, newValue) => {
        this.setState({selectedTab: newValue, tooltipApp: undefined});
    };

    //Θ(N) where N is length of appsToDisplay
    //Selects all the apps for a user
    onSelectAll = () => {
        let setup = {...this.props.setup};
        this.props.setup.appsToDisplay.forEach((app, idx) => {
            if (
                (this.state.selectedTab === AppsTabMapping.ODU && app.isODU) ||
                (this.state.selectedTab === AppsTabMapping.EVMS &&
                    app.isEVMS) ||
                this.state.selectedTab === AppsTabMapping.ALL
            ) {
                if (setup.Apps[app.id] === -1) {
                    setup['Apps'] = {...(setup?.Apps ?? {}), [app.id]: idx};
                }
            }
        });
        this.props.saveSetup(setup);
    };

    //Θ(1) Clears all the selected applications
    onClearSelection = () => {
        let setup = {...this.props.setup};
        //Delete all selected apps if All tab is selected
        if (this.state.selectedTab === AppsTabMapping.ALL) {
            setup.Apps = Object.entries(setup.Apps).reduce(
                (clearedApps, [appId, selected]) => {
                    return {
                        ...clearedApps,
                        [appId]: -1
                    };
                },
                {}
            );
        } else {
            this.props.setup.appsToDisplay.forEach(app => {
                if (
                    (this.state.selectedTab === AppsTabMapping.ODU &&
                        app.isODU) ||
                    (this.state.selectedTab === AppsTabMapping.EVMS &&
                        app.isEVMS)
                ) {
                    setup.Apps[app.id] = -1;
                }
            });
        }

        this.props.saveSetup(setup);
    };

    render() {
        return (
            <React.Fragment>
                <Stack
                    id={this.props.id + '__stack_wrapper'}
                    direction={{xs: 'row', sm: 'row'}}
                    justifyContent="space-between"
                    sx={{borderBottom: '1px solid #CCCCCC'}}
                >
                    <Tabs
                        className="myOdu__tabs px-0"
                        value={this.state.selectedTab}
                        onChange={this.setSelectedTab}
                        aria-label={'App Categories'}
                        id={this.props.id + '__tabs_schools'}
                    >
                        <PortalTooltip
                            title="Show apps used by ODU users"
                            id={this.props.id + '__tooltip_odu'}
                        >
                            <Tab
                                label="ODU"
                                id={this.props.id + '__tab_odu'}
                                aria-controls={this.props.id + '__tabPanel_odu'}
                            />
                        </PortalTooltip>

                        <PortalTooltip
                            title="Show apps used by Virginia Health Sciences users"
                            id={this.props.id + '__tooltip_evhsc'}
                        >
                            <Tab
                                label="VHS"
                                id={this.props.id + '__tab_evhsc'}
                                aria-controls={
                                    this.props.id + '__tabPanel_evhsc'
                                }
                            />
                        </PortalTooltip>

                        <PortalTooltip
                            title="Show all apps"
                            id={this.props.id + '__tooltip_all'}
                        >
                            <Tab
                                label="All"
                                id={this.props.id + '__tab_all'}
                                aria-controls={this.props.id + '__tabPanel_all'}
                            />
                        </PortalTooltip>
                    </Tabs>

                    {this.props.isSmall ? (
                        <div>
                            <IconButton
                                className="myOdu__widgetMenu_button myOdu__iconButton"
                                id={'setup__iconButton_selections'}
                                aria-label={'Select'}
                                color="inherit"
                                onClick={this.dropAnchor}
                            >
                                <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    size="sm"
                                    id="setup__icon_ellipsis"
                                />
                            </IconButton>
                            <Menu
                                anchorEl={this.state.anchor}
                                id={'setup__menu_selections'}
                                disableScrollLock={true}
                                open={this.state.isOpen}
                                onClose={this.pullAnchor}
                                onClick={this.pullAnchor}
                                transformOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top'
                                }}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'bottom'
                                }}
                            >
                                <MenuItem
                                    id="setup__menuItem_selectAll"
                                    onClick={this.onSelectAll}
                                >
                                    Select All
                                </MenuItem>
                                <MenuItem
                                    id="setup__menuItem_clear"
                                    onClick={this.onClearSelection}
                                >
                                    Clear Selection
                                </MenuItem>
                            </Menu>
                        </div>
                    ) : (
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{mt: 2, mb: 1}}
                            className="mt-sm-0"
                            id={this.props.id + '__stack_selectionButtons'}
                        >
                            <Button
                                sx={{mr: 1}}
                                size="small"
                                id={this.props.id + '__button_selectAll'}
                                className="myOdu__button setUpButton selectAll secondary"
                                variant="outlined"
                                onClick={this.onSelectAll}
                            >
                                Select All
                            </Button>
                            <Button
                                size="small"
                                id={this.props.id + '__button_clear'}
                                className="myOdu__button setUpButton clearSelection secondary"
                                variant="outlined"
                                onClick={this.onClearSelection}
                            >
                                Clear
                            </Button>
                        </Stack>
                    )}
                </Stack>

                <TabPanel
                    value={this.state.selectedTab}
                    index={AppsTabMapping.ODU}
                    id={this.props.id + '__tabPanel_odu'}
                    aria-lablledby={this.props.id + '__tab_odu'}
                >
                    <Grid
                        container
                        alignItems={'stretch'}
                        sx={{p: 0}}
                        id={this.props.id + '__grid_odu'}
                    >
                        {/* //TODO: add clean string  */}
                        {this.props.setup.appsToDisplay?.map(
                            app =>
                                app.isODU && (
                                    <Grid
                                        item
                                        sx={{pt: 1}}
                                        xl={3}
                                        lg={4}
                                        md={4}
                                        sm={6}
                                        xs={6}
                                        id={
                                            this.props.id +
                                            '__gridItem_odu_' +
                                            app.name
                                        }
                                    >
                                        <Box
                                            sx={{mr: 1, height: '100%'}}
                                            id={
                                                this.props.id +
                                                '__gridItemBox_odu_' +
                                                app.name
                                            }
                                        >
                                            <App
                                                app={app}
                                                id={this.props.id + '__app_odu'}
                                                selectedAppsCount={
                                                    this.props.selectedAppsCount
                                                }
                                                saveSelectedAppsCount={
                                                    this.props
                                                        .saveSelectedAppsCount
                                                }
                                                updateTooltip={
                                                    this.updateTooltip
                                                }
                                                tooltipApp={
                                                    this.state.tooltipApp
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                )
                        )}
                    </Grid>
                </TabPanel>
                <TabPanel
                    value={this.state.selectedTab}
                    index={AppsTabMapping.EVMS}
                    id={this.props.id + '__tabPanel_evhsc'}
                    aria-lablledby={this.props.id + '__tab_evhsc'}
                >
                    <Grid
                        container
                        alignItems={'stretch'}
                        sx={{p: 0}}
                        id={this.props.id + '__grid_evhs'}
                    >
                        {this.props.setup.appsToDisplay?.map(
                            app =>
                                app.isEVMS && (
                                    <Grid
                                        item
                                        sx={{pt: 1}}
                                        xl={3}
                                        lg={4}
                                        md={4}
                                        sm={6}
                                        xs={6}
                                        id={
                                            this.props.id +
                                            '__gridItem_evhsc_' +
                                            app.name
                                        }
                                    >
                                        <Box
                                            sx={{mr: 1, height: '100%'}}
                                            id={
                                                this.props.id +
                                                '__gridItemBox_evhsc_' +
                                                app.name
                                            }
                                        >
                                            <App
                                                app={app}
                                                id={
                                                    this.props.id +
                                                    '__app_evhsc'
                                                }
                                                selectedAppsCount={
                                                    this.props.selectedAppsCount
                                                }
                                                saveSelectedAppsCount={
                                                    this.props
                                                        .saveSelectedAppsCount
                                                }
                                                updateTooltip={
                                                    this.updateTooltip
                                                }
                                                tooltipApp={
                                                    this.state.tooltipApp
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                )
                        )}
                    </Grid>
                </TabPanel>
                <TabPanel
                    value={this.state.selectedTab}
                    index={AppsTabMapping.ALL}
                    id="apps__tabPanel_all"
                    aria-lablledby="apps__tab_all"
                >
                    <Grid container alignItems={'stretch'} sx={{p: 0}}>
                        {this.props.setup.appsToDisplay?.map(app => (
                            <Grid
                                item
                                sx={{pt: 1}}
                                xl={3}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={6}
                                id={
                                    this.props.id + '__gridItem_all_' + app.name
                                }
                            >
                                <Box
                                    sx={{mr: 1, height: '100%'}}
                                    id={
                                        this.props.id +
                                        '__gridItemBox_all_' +
                                        app.name
                                    }
                                >
                                    <App
                                        app={app}
                                        id={this.props.id + '__app_all'}
                                        selectedAppsCount={
                                            this.props.selectedAppsCount
                                        }
                                        saveSelectedAppsCount={
                                            this.props.saveSelectedAppsCount
                                        }
                                        updateTooltip={this.updateTooltip}
                                        tooltipApp={this.state.tooltipApp}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        setup: state.setupReducer.setup,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    saveSetup: setup => dispatch(saveSetup(setup))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithSmallScreen(Apps));
