import {toTitleCase} from '../../common/utils';
import {getCampusFromSiteCode} from '../utils';

//θ(1) Returns all the columns of the datagrid
export const getColumns = () => {
    return [
        {
            field: 'LASTNAME',
            headerName: 'Last Name',
            width: 100,
            valueGetter: (value, row) => {
                const lastName = row.SNAME.split(',')[0];
                return toTitleCase(lastName);
            }
        },
        {
            field: 'FIRSTNAME',
            headerName: 'First and Middle Name',
            width: 200,
            valueGetter: (value, row) => {
                const firstName = row.SNAME.split(',')[1];
                return toTitleCase(firstName);
            }
        },
        {
            //TODO: Needs to be updated
            field: 'MIDAS',
            headerName: 'MIDAS'
        },
        {
            field: 'SPRIDEN_ID',
            headerName: 'Student ID'
        },
        {
            field: 'EMAIL',
            headerName: 'Email'
        },
        {
            field: 'SGBSTDN_LEVL_CODE',
            headerName: 'Level'
        },
        {
            //TODO: Needs to be updated
            field: 'SGBSTDN_STYP_CODE',
            headerName: 'Student Type'
        },
        {
            //TODO: Needs to be updated
            field: 'CLAS_CODE',
            headerName: 'Class'
        },
        {
            field: 'SGBSTDN_DEGC_CODE_1',
            headerName: 'Degree'
        },
        {
            field: 'SGBSTDN_SITE_CODE',
            headerName: 'Site'
        },
        {
            field: 'CAMPUS',
            headerName: 'Campus',
            valueGetter: (value, row) =>
                getCampusFromSiteCode(row.SGBSTDN_SITE_CODE)
        },
        {
            field: 'STDN_DEPT_CODE',
            headerName: 'Department',
            valueGetter: (value, row) => {
                const department = row.STDN_DEPT_CODE + ' - ' + row.DEPT_DESC;
                return department;
            }
        },
        {
            field: 'ADVR_NAME',
            headerName: 'Current Advisor'
        },
        {
            field: 'TYPE',
            headerName: 'Type',
            valueGetter: (value, row) => {
                const type = row.MAJR_CODE_CONC_1 === null ? 'Major' : 'Minor';
                return type;
            }
        },
        {
            field: 'FIELDOFSTUDY',
            headerName: 'Field of Study',
            width: 200,
            valueGetter: (value, row) => {
                const fieldOfStudy =
                    row.MAJR_CODE_CONC_1 === null
                        ? row.MAJR_CODE_1 + ' - ' + row.MAJR_CODE_1_DESC
                        : row.MAJR_CODE_CONC_1 +
                          ' - ' +
                          row.MAJR_CODE_CONC_1_DESC;
                return fieldOfStudy;
            }
        },
        {
            field: 'SGRADVR_PRIM_IND',
            headerName: 'Primary Advisor'
        },
        {
            field: 'ENROLLED',
            headerName: 'Enrolled'
        }
    ];
};
