import * as React from 'react';
import { Card, Typography, Link, Box, IconButton, Grid, Stack, Chip} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faLoader, faCircleCheck } from '@fortawesome/pro-light-svg-icons';
// import { faGripDots, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
// import { faEye } from '@fortawesome/free-solid-svg-icons';
import {isMobile} from 'react-device-detect';
import PortalTooltip from '../../../common/PortalTooltip';

export class WidgetCard extends React.Component {

    getWidgetTooltip = () => {

        if(this.props.required) {
            return "The " + this.props.widget?.title + ' widget cannot be hidden.'
        }

        return (this.props.isSelected ? 'Hide ': ' Show ') + this.props.widget?.title + ' Widget';
    }

    render(){
        return (   
            //TODO move inline style to scss
            <Card sx={{ p:1, height: '100%', width: '100%', borderBottom: '1px solid rgb(220, 220, 222)'}} elevation={0}>
                <Grid container direction='row' gap={1} justifyContent={'flex-start'} alignItems={isMobile ? 'flex-start' : 'center'} wrap='nowrap'>
                    <Grid item xs={'auto'}>
                        <PortalTooltip title={ this.getWidgetTooltip() } >
                            <Box>
                                {/* //TODO: call string cleaner here */}
                                {this.props.required ? 
                                    <IconButton aria-label={ this.getWidgetTooltip() } disabled = {this.props.disabled}><FontAwesomeIcon icon={faCircleCheck} size="small" /></IconButton>
                                :
                                <IconButton
                                    onClick={() => {
                                        this.props.onUpdateWidgetSelection(this.props.widget);
                                    }} 
                                    size="small"
                                    id={ this.props.id + '__applicationCard__button_favorite_' + this.props.widget?.title.replace( ' ', '' ) }
                                    disabled = {this.props.disabled}
                                    className="myOdu__iconButton"
                                    aria-label={ this.getWidgetTooltip() }
                                >
                                    {!this.props?.loadingWidgets[this.props.widget.id] ? (
                                        <FontAwesomeIcon icon={this.props.isSelected ? faEye : faEyeSlash}  size="small" />
                                    ) : (
                                        <FontAwesomeIcon icon={faLoader} className="myOdu__rotate" size="small" />
                                    )}
                                </IconButton>
                                }
                               

                            </Box>
                        </PortalTooltip>
                    </Grid>

                    <Grid item container xs={10} alignItems={'flex-start'}>
                        <Stack>
                            <Typography component="h4" className="appCardTitle">
                                {this.props.widget?.title}
                            </Typography>
                            <Typography component="p">
                                {this.props.widget?.desc}
                            </Typography>
                            {/* <Typography component="p">
                                Categories: {this.props.app.isODU && 'ODU'}{this.props.app.isODU && this.props.app.isEVMS && ', '} {this.props.app.isEVMS && 'VHS'}
                            </Typography> */}
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        );
    }
}

export default WidgetCard;

