import React, {Component} from 'react';
import {Button, Card, CardActionArea, Typography} from '@mui/material';
import WithRouter from '../Layout/Router/WithRouter';

class PageNavigationCard extends Component {
    render() {
        const {subPage, id, url} = this.props;
        return (
            <Button 
                id={`${id}__button_${subPage.id}`}
                variant='outlined' 
                className='myOdu__pageNavigationCard'
                onClick={() => this.props.navigate(`/${url}/${subPage.page}`)}
            >
                {subPage.title}
            </Button>
        );
    }
}

export default WithRouter(PageNavigationCard);
