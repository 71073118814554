import React, {Component} from 'react';
import PortalModal from '../common/PortalModal';

class UnassignConfirmation extends Component {

    state = {

    };

    render() {
        return <React.Fragment>
            <PortalModal
                maxWidth="sm"
                onClose={this.props.onClose}
                isOpen={this.props.isOpen}
                primaryButtonTitle="unassign"
                onPrimaryClick={this.props.onPrimaryClick}
                isPrimaryButtonDisabled={this.props.isPrimaryButtonDisabled}
                secondaryButtonTitle="cancel"
                onSecondaryClick={this.props.onSecondaryClick}
                isCloseable={true}
                title={this.props.title}
            >
                {
                    this.props.message
                }
            </PortalModal>
        </React.Fragment>
    }

}

export default UnassignConfirmation