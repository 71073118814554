import React, {Component} from 'react';
import {Typography, Box, Stack, IconButton} from '@mui/material';
import GPADetails from '../Details/GpaDetails';
import {faEye, faEyeSlash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PortalTooltip from '../../common/PortalTooltip';

class GpaAcademicStanding extends Component {
    render() {
        return <Box className="itemBox">
                    <Stack direction="row" justifyItems={"center"} justifyContent={"center"}>
                        <Typography component="h4" sx={{mt: 1}}>Grade Point Average</Typography>
                        
                        <PortalTooltip title={this.props.isDisplayGPA ? "Hide GPA" : "GAP Hidden, Show GPA"}>
                            <IconButton id="myPrograms__button_toggleGPA" aria-label="hide-gpa" onClick={this.props.toggleIsDisplayGPA} >
                                <FontAwesomeIcon size={"sm"} id={`gpaDetails__icon_${this.props.isDisplayGPA ? 'eye' : 'eyeSlash'}`} icon={ this.props.isDisplayGPA ? faEye : faEyeSlash } />
                            </IconButton>
                        </PortalTooltip>
                    </Stack>
                    <GPADetails
                        id="myPrograms__current_gpa_details"
                        title="Current GPA"
                        data={this.props.programDetails?.CUML_GPA}
                        isDisplayGPA={this.props.isDisplayGPA}
                        toggleIsDisplayGPA={this.props.toggleIsDisplayGPA}
                    />

                    <hr className="mx-4"/>
                    <Typography component="h4" sx={{mt:2}}>Academic Standing</Typography>
                    <Typography
                        id={'myPrograms__academicStanding'}
                        component="p"
                        variant="small"
                    >
                        {this.props.programDetails?.ACADEMIC_STANDING_DESC ||
                            'No academic standing found'}
                    </Typography>
                </Box>
    }
}

export default GpaAcademicStanding;
