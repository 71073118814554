import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '..';
import { isUserAdmin, isUserInGroup } from '../utils';
import Unauthorized from './Unauthorized';
import { adminGroupName } from '../../common/constants';

export default function ValidatedRoute(props) {
  const trueUser = useSelector(state => state.AWSReducer.user)
  const isAllowed = isUserAdmin(trueUser) || props.validator(trueUser);
  return isAllowed ? props.element : <Layout><Unauthorized /></Layout>
}