import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { Component } from 'react';
import { ICON_PATH, MYAPPS_DIRECTIONS } from '../../../common/constants';
import PortalTooltip from '../../../common/PortalTooltip';
import { isMobile } from 'react-device-detect';

class AppCard extends Component {
    state ={

    }

    render() {
        const {leftAppIndex, rightAppIndex, upAppIndex, downAppIndex} = this.props.getSideAppsIndexes(this.props.app.id);
        const isLeft = leftAppIndex > -1;
        const isRight = rightAppIndex > -1; 
        const isDown = downAppIndex > -1;
        const isUp = upAppIndex > -1;

        return <React.Fragment>
            <Card sx={{width: '100%'}} className={'myOdu__appCard ' + ("active ") + this.props.className } elevation={0}>
                <Stack direction={'column'} justifyContent={'center'}>
                    {
                        !this.props.isDNDSelected ? <PortalTooltip title={isUp ? "Move " + this.props.app?.name + " up" : ""}>
                            <Box className="text-center">
                                <IconButton aria-label={"Move " + this.props.app?.name + " up"} className="myOdu__iconButton moveCardButton up" onClick={() => {this.props.moveApp(this.props.app.id, upAppIndex)}} disabled={!isUp} style={{opacity: !isUp ? '0%' : '100%'}}>
                                    <FontAwesomeIcon icon={faArrowUp} size={"sm"} /> 
                                </IconButton>
                            </Box>
                        </PortalTooltip> :
                        <PortalTooltip title={!isMobile ? ("Hold to move " + this.props.app?.name) : ""}>
                            <Box display={'flex'} justifyContent={'flex-start'} {...this.props.listeners}>
                                <IconButton aria-label={"Hold to move " + this.props.app?.name} className="myOdu__iconButton" onClick={() => {this.props.moveApp(this.props.app.id, upAppIndex)}}>
                                    <FontAwesomeIcon icon={faGripDotsVertical} size={"sm"} /> 
                                </IconButton>
                            </Box>
                        </PortalTooltip>
                    }

                    <Stack direction={'row'} justifyContent={'center'} className="centerRow">
                        <PortalTooltip title={isLeft ? "Move " + this.props.app?.name + " left" : ""}>
                            <IconButton aria-label={"Move " + this.props.app?.name + " left"} className="myOdu__iconButton moveCardButton left" onClick={() => {this.props.moveApp(this.props.app.id, leftAppIndex)}} disabled={!isLeft} style={{opacity: !isLeft ? '0%' : '100%'}}>
                                <FontAwesomeIcon icon={faArrowLeft}  size={"sm"} /> 
                            </IconButton>
                        </PortalTooltip>

                        <Stack className="appIcon" direction={'column'} justifyContent={'center'}>
                            <Box className="wrapperBox" display={'flex'} justifyContent={'center'}>
                                <Box sx={{ width: '40px'}}  className={'iconImage '}>
                                    <img src={(ICON_PATH + this.props.app?.icon)} alt={this.props.app?.name ?? "application icon"} />
                                </Box>
                            </Box>

                            <Typography className="appCardTitle">
                                {this.props.app?.name}
                            </Typography>
                        </Stack>

                        <PortalTooltip title={isRight ? "Move " + this.props.app?.name + " right": ""}>
                            <IconButton aria-label={"Move " + this.props.app?.name + " right"} className="myOdu__iconButton moveCardButton right" onClick={() => {this.props.moveApp(this.props.app.id, rightAppIndex)}} disabled={!isRight} style={{opacity: !isRight ? '0%' : '100%'}}>
                                <FontAwesomeIcon icon={faArrowRight} size={"sm"}  /> 
                            </IconButton>
                        </PortalTooltip>
                    </Stack>

                    <PortalTooltip title={isDown ? "Move " + this.props.app?.name + " down": ""}>
                        <IconButton aria-label={"Move " + this.props.app?.name + " down"} sx={{border: '1px solid orange' }} className="myOdu__iconButton moveCardButton down text-center" onClick={() => {this.props.moveApp(this.props.app.id, downAppIndex)}} disabled={!isDown} style={{opacity: !isDown ? '0%' : '100%'}}>
                            <FontAwesomeIcon icon={faArrowDown} size={"sm"} /> 
                        </IconButton>
                    </PortalTooltip>
                </Stack>
            </Card>
        </React.Fragment>
    }
}

export default AppCard;