import React, {Component} from 'react';
import PageHeader from '../PageHeader';
import {Box, Container} from '@mui/material';
import {PageDetails} from '../config';
import SAMFilter from '../SAMFilter';
import {getColumns} from './utils';
import StudentDetails from '../StudentDetails';
import {getData, postData} from '../../../DataAccessLayer';
import {sam} from '../../../DataAccessLayer/services';
import {toast} from 'react-toastify';
import UnassignConfirmation from '../UnassignConfirmation';

class DifferentMajor extends Component {
    state = {
        id: 'studentsWithDifferentMajor',
        title: '',
        isLoading: false,
        activeDepartment: {},
        students: [],
        isSearchResult: false,
        columns: getColumns(),
        actionMenuItems: [],
        selectedStudents: [],
        isConfirmationPopup: false,
        errorMessage: ''
    };

    componentDidMount() {
        this.loadPageTitle();
        this.loadActionMenuItems();
    }

    //θ(1) Toggles isLoading
    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        });
    };

    //θ(1) Toggles isSearchResult
    toggleIsSearchResult = (isSearchResult = !this.state.isSearchResult) => {
        this.setState({
            isSearchResult
        });
    };

    //θ(1) Toggles isConfirmationPopup
    toggleIsConfirmationPopup = (
        isConfirmationPopup = !this.state.isConfirmationPopup
    ) => {
        this.setState({
            isConfirmationPopup
        });
    };

    //θ(N) where N is the number of pages in PageDetails
    //Retrieve page title from pageDetails using pageid
    loadPageTitle = () => {
        const page = Object.values(PageDetails).find(
            page => page.id === this.state.id
        );
        this.setState({
            title: page.title
        });
    };

    //θ(1) Sets action menu items
    loadActionMenuItems = () => {
        const {id} = this.state;

        const actionMenuItems = [
            {
                id: `${id}__menuItem_removeAdvsior`,
                label: 'Remove Advisor',
                onClick: this.toggleIsConfirmationPopup
            }
        ];

        this.setState({
            actionMenuItems
        });
    };

    //θ(1) Sets the selected students from datagrid
    onStudentSelected = selectedStudents => {
        this.setState({
            selectedStudents
        });
    };

    //θ(1) Search students for seleted advisors
    //TODO add code to call search api
    searchStudents = selectedValues => {
        //Set searched department as selected department as users may change department while UI showing data of previous department
        const {department} = selectedValues;
        this.setState({
            activeDepartment: department,
            students: []
        });
        this.toggleIsSearchResult(true);
        this.loadStudents(department);
    };

    //θ(1) Loads students for selected department
    loadStudents = (department = this.state.activeDepartment) => {
        this.toggleIsLoading(true);
        this.setState({
            students: []
        });
        const departmentCode = department.STVDEPT_CODE;
        getData(sam.differentMajor(departmentCode), true)
            .then(students => {
                this.setState({
                    students,
                    errorMessage: ''
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    errorMessage: 'We’re currently experiencing an issue retrieving student data. Please try again later.'
                });
            })
            .finally(_ => {
                this.toggleIsLoading(false);
            });
    };

    //θ(1) Unassign selected students
    unassignStudents = () => {
        this.toggleIsLoading(true);

        const selectedStudents = this.state.selectedStudents.map(
            student => student.split('_')[0]
        );

        const body = {
            studentPidms: selectedStudents
        };

        const studentMessage =
            this.state.selectedStudents.length > 1 ? 'Students' : 'Student';

        postData(sam.unassignStudents, body, true)
            .then(result => {
                toast.success(`${studentMessage} Removed from Advisor`);
                this.toggleIsLoading(false);
                this.toggleIsConfirmationPopup(false);
                this.loadStudents();
            })
            .catch(err => {
                console.log(err);
                toast.error(`Unable to Remove ${studentMessage} from Advisor`);
                this.toggleIsLoading(false);
            });
    };

    render() {
        const id = this.state.id;
        const studentMessage =
            this.state.selectedStudents.length > 1 ? 'Students' : 'Student';

        return (
            <React.Fragment>
                <div
                    id={`${id}__div_pageWrapper`}
                    className={`myOdu__${id}`}
                    style={{}}
                >
                    <Container
                        id={`${id}__container_pageWrapper`}
                        className="myOdu__container secondaryPage"
                    >
                        <PageHeader
                            id={this.state.id}
                            title={this.state.title}
                            isSubPage
                        />

                        <Box id={`${id}__box_wrapper`} className={'wrapper'}>
                            <UnassignConfirmation
                                maxWidth="sm"
                                onClose={() => {
                                    this.toggleIsConfirmationPopup(false);
                                }}
                                isOpen={this.state.isConfirmationPopup}
                                primaryButtonTitle="unassign"
                                onPrimaryClick={this.unassignStudents}
                                isPrimaryButtonDisabled={this.state.isLoading}
                                secondaryButtonTitle="cancel"
                                onSecondaryClick={() => {
                                    this.toggleIsConfirmationPopup(false);
                                }}
                                isCloseable={true}
                                title={`Unassign Selected ${studentMessage}`}
                                message={`Are you sure you want to unassign selected ${studentMessage.toLowerCase()}? This action cannot be undone.`}
                            />
                            <SAMFilter
                                id={id}
                                isDepartmentCode
                                isDepartmentRequired
                                departmentsLength={{xs: 12, xl: 5}}
                                primaryButtonText={'Search'}
                                onPrimary={this.searchStudents}
                                disablePrimaryButton={this.state.isLoading}
                                primaryButtonLength={{xs: 12, xl: 1}}
                                widgetName = {this.state.title}
                            />
                            {this.state.isSearchResult && (
                                <StudentDetails
                                    id={`${id}Details`}
                                    students={this.state.students}
                                    columns={this.state.columns}
                                    onStudentSelected={this.onStudentSelected}
                                    actionMenuItems={this.state.actionMenuItems}
                                    disableActionButton={
                                        this.state.selectedStudents.length === 0
                                    }
                                    noStudentMessage={
                                        this.state.errorMessage.length ?
                                        this.state.errorMessage :
                                        <>
                                            There are no students with different
                                            major in{' '}
                                            <strong>
                                                {
                                                    this.state.activeDepartment
                                                        .STVDEPT_DESC
                                                }
                                            </strong>
                                            .
                                        </>
                                    }
                                    message={`Students with Different Major in ${this.state.activeDepartment.STVDEPT_DESC}`}
                                    isLoading={this.state.isLoading}
                                />
                            )}
                        </Box>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default DifferentMajor;
