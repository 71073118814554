module.exports = {
    CRITICAL: 'Critical',
    ACTION_HIDE: 'hide',
    ACTION_UNHIDE: 'unhide',
    ALERT_KEY_HOLDS: 'holds',
    ALERT_KEY_UPCOMING_HOLDS: 'upcomingHolds',
    ALERT_KEY_BALANCE: 'balance',
    ALERT_KEY_FINANCIAL_AID: 'financialAid',
    CAMPUS: 'campus',
    HOUSING: 'isHousing',
    ICON_PATH: '/img/logos/',
    MOVE_WIDGET_UP: 'UP',
    MOVE_WIDGET_DOWN: 'DOWN',
    DayMapping: {
        M: 'Monday',
        T: 'Tuesday',
        W: 'Wednesday',
        R: 'Thursday',
        F: 'Friday',
        S: 'Saturday',
        U: 'Sunday'
    },
    zoomDevEmail: 'knavulur@365dev.odu.edu',
    CHECKLIST_YES_INDICATOR: 'Y',
    SYSTEM_STATUS_IDENTIFIED: 'identified',
    SYSTEM_STATUS_FIXED: 'fixed',
    adminGroupName: 'DL MyODU Portal Admins',
    powerUserGroupName: 'DL MyODU Portal Power Users',
    samUserSuperGroupName: 'DL MyODU Portal SAM Super Users',
    samUserPowerGroupName: 'DL MyODU Portal SAM Power Users',
    samUserGroupName: 'DL MyODU Portal SAM Users',
    advisorRoleName: 'ADVISOR',
    impersonationGroupName: 'DL MyODU Portal Impersonators',
    facultyRoleName: 'FACULTY',
    staffRoleName: 'STAFF',
    studentRoleName: 'STUDENT',
    medicalStudentRoleName: 'SCHOOL_OF_MEDICINE_STUDENT',
    healthProfessionsStudentRoleName: 'SCHOOL_OF_HEALTH_PROF_STUDENT',
    medicalResidentRoleName: 'MEDICAL_RESIDENT',
    //EVCM = people at the Fort Norfolk Campus
    evmcRoleName: 'EVMC',
    evmcStaffRoleName: 'EVMC_STAFF',
    evmcFacultyRoleName: 'EVMC_FACULTY',
    //ECHSC = people involved in the school, could be Fort Norfolk campus, could be Norfolk Main campus
    evhscRoleName: 'EVHSC',
    evhscFacultyRoleName: 'EVHSC_FACULTY',
    evhscStaffRoleName: 'EVHSC_STAFF',
    GOOGLE_ANALYTICS_KEY: 'GTM-5HPKDTRG',
    mealsThreshold: '10',
    flexPointsThreshold: '50',
    MAIN_BRANCH: 'main',
    CURRENT_TERM: 'CURRENT_TERM',
    cardCenterMonarch: 'https://cardcenter.odu.edu/monarch',
    studentAccount: 'https://secure.touchnet.com/C21192_tsa/web/caslogin.jsp',
    meetingStartsWithin: 30,
    monthsList: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],
    FACULTY_EMAIL_CODE: 'ODUF',
    STUDENT_EMAIL_CODE: 'ODUS',
    TIME_ZONE: 'America/New_York',
    REDIRECT_TIMEOUT: 1,
    //This is the default role which will be used if a user does not have any roles
    DEFAULT_ROLE: {
        STUDENT: true
    },
    MYAPPS_LEFT_PANEL: 'myAppsLeftPanel',
    MYAPPS_RIGHT_PANEL: 'myAppsRightPanel',
    MYAPPS_CENTER_PANEL: 'myAppsCenterPanel',
    MYAPPS_DIRECTIONS: {
        LEFT: 'LEFT',
        RIGHT: 'RIGHT',
        UP: 'UP',
        DOWN: 'DOWN'
    },
    //ITEMS_COUNT_PANEL must be divisible by ITEM_COUNT_ROW
    MYAPPS_ITEMS_COUNT_PANEL: 9,
    MYAPPS_ITEMS_COUNT_ROW: 3,
    WAITLIST_TUTORIAL:
        'https://www.odu.edu/sites/default/files/documents/how-to-use-waitlisting.pdf',
    LEO_REGISTRATION:
        'https://ssb-prod.ec.odu.edu/PROD/twbkwbis.P_GenMenu?name=bmenu.P_RegMnu',
    // Probabilty to fetch and show random infotip for user. Example 0.2 is 20%
    INFOTIPS_DISPLAY_PROBABILTY: 0.2,
    // Number of infotips should be displayed
    INFOTIPS_DISPLAY_NUMBER: 1,
    //The wait time to trigger update preferences api call on widget's expansion status change
    WIDGETS_THROTTLE_DELAY: 3000,
    //Default display options added to user preferences
    DEFAULT_DISPLAY_OPTIONS: {
        isSidebar: true
    },
    MOVE_CONTACT_UP: 'UP',
    MOVE_CONTACT_DOWN: 'DOWN',
    ODU_DIRECTORY: "http://odu.edu/directory"
};
