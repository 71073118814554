import {Link, Tooltip, Typography} from '@mui/material/';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/pro-light-svg-icons';
import {CURRENT_TERM, DayMapping} from '../common/constants';
import dayjs from 'dayjs';
import PortalTooltip from '../common/PortalTooltip';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const getIsClassScheduled = course => {
    const isClassScheduled =
        (course?.MEETING_DAYS && course?.BEGIN_TIME && course?.END_TIME) !==
        null;

    return isClassScheduled;
};

export const getRowHeight = params => {
    const descriptionLength = params.model.SCHEDULE?.length ?? 0;

    return descriptionLength > 1 ? 80 : 40;
};

export const getTeachingColumns = term => {
    return [
        {field: 'CRNS', headerName: 'CRNS', width: '65', flex: 1},
        //TODO: add sorter
        {
            field: 'longTitle',
            headerName: 'Class',
            width: '275',
            isClickable: true,
            flex: 2,
            renderCell: params => {
                // if (term === CURRENT_TERM) {
                return (
                    <PortalTooltip title="Open in Course Collaboration Tool">
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                //Setting it to the row as we are saving the row to the cct reducer
                                params.row.isTeaching = true;
                                params.row.isClassScheduled =
                                    getIsClassScheduled(params.row);
                                params.colDef.onClick(params.row);
                            }}
                        >
                            {params.row.SUBJECT_CODE +
                                ' ' +
                                params.row.COURSE_NUMBER +
                                ' ' +
                                params.row.COURSE_TITLE}
                        </Link>
                    </PortalTooltip>
                );
                // } else {
                //     return (
                //         params.row.SUBJECT_CODE +
                //         ' ' +
                //         params.row.COURSE_NUMBER +
                //         ' ' +
                //         params.row.COURSE_TITLE
                //     );
                // }
            }
        },
        {
            field: 'daysTime',
            headerName: 'Schedule',
            width: '200',
            flex: 2,
            renderCell: params => {
                const parsedSchedule = params.row.SCHEDULE?.map(entry => {
                    let weeks = entry.MEETING_DAYS?.replace('<BR>', '')
                        .split('')
                        .reduce((weeks, day, idx) => {
                            return (
                                weeks +
                                (idx !== 0 ? ', ' : '') +
                                DayMapping[day]
                            );
                        }, '');

                    return entry?.MEETING_DAYS
                        ? weeks +
                              ' at ' +
                              `${dayjs(entry.BEGIN_TIME, 'HH:mm').format(
                                  'h:mm A'
                              )} - ${dayjs(entry.END_TIME, 'HH:mm').format(
                                  'h:mm A'
                              )}`
                        : 'No established schedule';
                }).join('\n');

                return (
                    <div
                        style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}
                    >
                        {parsedSchedule}
                    </div>
                );
            }
        }
    ];
};

export const getTakingColumns = term => {
    return [
        {field: 'CRN', headerName: 'CRN', width: '100'},
        //TODO: add sorter to class
        {
            field: 'COURSE_TITLE',
            headerName: 'Class',
            width: '275',
            isClickable: true,
            flex: 2,
            renderCell: params => {
                // if (term === CURRENT_TERM) {
                return (
                    <PortalTooltip title="Open in Course Collaboration Tool">
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                params.row.isTaking = true;
                                params.row.CRNS =
                                    params.row.CRN;
                                params.row.SSBSECT_TERM_CODE =
                                    params.row.TERM_CODE;
                                params.row.isClassScheduled =
                                    getIsClassScheduled(params.row);
                                params.colDef.onClick(params.row);
                            }}
                        >
                            {params.row.SUBJECT_CODE +
                                ' ' +
                                params.row.COURSE_NUMBER +
                                ' ' +
                                params.row.COURSE_TITLE}
                        </Link>
                    </PortalTooltip>
                );
                // } else {
                //     return (
                //         params.row.SUBJECT_5 +
                //         ' ' +
                //         params.row.COURSE_NUMBER_5 +
                //         ' ' +
                //         params.row.COURSE_TITLE_5
                //     );
                // }
            }
        },
        {
            field: 'INSTRUCTOR',
            headerName: 'Instructor',
            width: '200',
            flex: 2,
            renderCell: params => {
                if (
                    params.row?.INSTRUCTOR_NAME != null
                ) {
                    // TODO: Fetch the instructor email from the API and make it a link if available
                    if (false) {
                        return (
                            <PortalTooltip
                                title={
                                    'Email ' +
                                    params.row?.INSTRUCTOR_NAME
                                }
                            >
                                <Link href={params.row.INSTRUCTOR_EMAIL}>
                                    {params.row?.INSTRUCTOR_NAME}
                                </Link>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    size="sm"
                                    style={{marginRight: '0.25rem'}}
                                />
                            </PortalTooltip>
                        );
                    } else {
                        return params?.row?.INSTRUCTOR_NAME;
                    }
                } //else no instructor
            }
        }
    ];
};
