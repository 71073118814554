import React from 'react';
import {Tooltip} from '@mui/material';

class PortalTooltip extends React.Component {
    state = {};

    render() {
        return (
            <span>
                <Tooltip
                    id={this.props.id}
                    title={this.props.title ?? 'Open in new window'}
                    enterDelay={750}
                    leaveDelay={0}
                    enterNextDelay={750}
                    open = {this.props.isOpen}
                    arrow
                    {...this.props}
                    slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                >
                    {this.props.children}
                </Tooltip>
            </span>
        );
    }
}

export default PortalTooltip;
