import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import CreditDetails from "../Details/CreditDetails";

class Major extends Component {
    render() {
        return <Box className="itemBox">
                <Typography component="h4">
                    {this.props.programDetails?.STUDENT_LEVEL_DESC ? this.props.programDetails?.STUDENT_LEVEL_DESC : 'No Program Found' }          
                </Typography>

                <Typography sx={{mt:2}} component="h4">Major </Typography>
                <Typography component="p" variant="small">
                    {this.props.programDetails?.MAJOR_DESC ?? 'No major found' }
                </Typography>
            </Box>
    }
}

export default Major;