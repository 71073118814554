import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, List, ListItem} from '@mui/material';
import {GridActionsCellItem} from '@mui/x-data-grid-pro';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import { cleanUpID } from '../../common/utils';

const getColumns = actionFunction => {
    return [
        {
            field: 'SUBJECT',
            valueGetter: (params, row) => row.SUBJECT,
            headerName: 'Subject',
            width: 80
        },
        {
            field: 'COURSE_NUMBER',
            valueGetter: (params, row) => row.COURSE_NUMBER,
            headerName: '#',
            width: 40
        },
        {
            field: 'COURSE_TITLE',
            valueGetter: (params, row) => row.COURSE_TITLE,
            headerName: 'Title',
            flex: 3
        },
        {
            field: 'locations',
            headerName: 'Tutoring Info/Location',
            minWidth: 150,
            flex: 2,
            renderCell: params => {
                return (
                    //TODO: if this is a list then the items inside need to be list items
                    <List disablePadding id={"tutoring__list_infoWrapper_" + cleanUpID(params.row.COURSE_TITLE) + '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER }
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}  
                    >
                        {params.row.locations.map((location, index) => (
                            <ListItem 
                                id={`tutoring__span_infoWrapper_` + cleanUpID(params.row.COURSE_TITLE) + '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER+'_'+ cleanUpID(location.text)} 
                                key={location.text}
                                style={{
                                    paddingLeft: '0.5rem',
                                    paddingRight: 0,
                                    width: 'auto'
                                }}
                            >
                                <Link
                                    id={`tutoring__location_url_` + cleanUpID(params.row.COURSE_TITLE)+ '_' + params.row.SUBJECT + '_' + params.row.COURSE_NUMBER+'_'+ cleanUpID(location.text)}
                                    mr={1}
                                    target="_blank"
                                    href={location.url}
                                >
                                    {location.text + (index !== params.row.locations.length - 1 && !location?.mapUrl ? ',' : '') }
                                </Link>
                                {location.mapUrl && (
                                    <Link
                                        id={`tutoring__link_map_` + cleanUpID(params.row.COURSE_TITLE)+ '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER}
                                        target="_blank"
                                        href={location.mapUrl}
                                    >
                                        <FontAwesomeIcon
                                            id={`tutoring__icon_map_` + cleanUpID(params.row.COURSE_TITLE)+ '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER}
                                            fixedWidth
                                            size="lg"
                                            icon={faLocationDot}
                                        />
                                        {
                                            index !== params.row.locations.length - 1 ? ',' : ''
                                        }
                                    </Link>
                                )}
                            </ListItem>
                        ))}
                    </List>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            cellClassName: 'actions',
            type: 'actions',
            getActions: event => [
                <GridActionsCellItem
                    id={`tutoring__gridActionsCellItem_delete_` + event.row.SUBJECT + event.row.COURSE_NUMBER}
                    icon={<FontAwesomeIcon id={`tutoring__icon_delete_` + event.row.SUBJECT + event.row.COURSE_NUMBER} icon={faTrash} />}
                    label="Delete"
                    onClick={() => actionFunction(event.row)}
                />
            ]
        },
        {field: 'createdAt', valueGetter: (params, row) => row.createdAt}
    ];
};

export default getColumns;
