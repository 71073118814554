import {SiteCodeCampusMapping} from './config';

//θ(N) where N is number of students
//Maps the selected students and forms the body {pidm, level, primInd} for each student
const createSelectedStudentObject = (students, selectedStudents = []) => {
    const studentsObj = {};

    students.forEach(student => {
        studentsObj[student.SPRIDEN_PIDM] = student;
    });

    const studentsData = selectedStudents.map(studentPIDM => {
        // const student = students.find(student => student.SPRIDEN_PIDM === studentPIDM);
        const student = studentsObj[studentPIDM];
        return {
            pidm: student.SPRIDEN_PIDM,
            level: student.SGBSTDN_LEVL_CODE,
            primInd: student.SGRADVR_PRIM_IND
        };
    });

    return studentsData;
};

/**
 * O(1), Gets the campus details based on the site code
 * @param {number} siteCode The site code
 * @return {string} The campus details
 */
const getCampusFromSiteCode = siteCode => {
    return SiteCodeCampusMapping[siteCode] ?? 'Unknown';
};

export {createSelectedStudentObject, getCampusFromSiteCode};
