export const SAVE_DEPARTMENTS = 'SAVE_DEPARTMENTS';
export const saveDepartments = (departments) => ({
    type: SAVE_DEPARTMENTS,
    payload: {departments}
});

export const SAVE_ADVISORS = 'SAVE_ADVISORS';
export const saveAdvisors = (advisors) => ({
    type: SAVE_ADVISORS,
    payload: {advisors}
});

export const SAVE_ADVISORS_BY_DEPT = 'SAVE_ADVISORS_BY_DEPT';
export const saveAdvisorsByDept = (advisorsByDept) => ({
    type: SAVE_ADVISORS_BY_DEPT,
    payload: {advisorsByDept}
});

export const SAVE_ADVISOR_TYPES = 'SAVE_ADVISOR_TYPES';
export const saveAdvisorTypes = (advisorTypes) => ({
    type: SAVE_ADVISOR_TYPES,
    payload: {advisorTypes}
});