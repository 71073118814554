import React, {Component} from 'react';
import Widget from '../common/Widget';
import {
    Stack,
    Link,
    Typography,
    Box,
    Alert,
    AlertTitle,
    Skeleton
} from '@mui/material';
import {Container, Row, Col} from 'reactstrap';
import {myRegistration} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import {connect} from 'react-redux';
import WithRouter from '../Layout/Router/WithRouter';
import moment from 'moment';
class MyRegistration extends Component {
    state = {
        title: ['my', 'Registration'],
        days: '--',
        hours: '--',
        minutes: '--',
        seconds: '--',
        futureSemester: '',
        currentSemester: '',
        countdownStartDate: '',
        countdownStartDateTime: '',
        currentTime: '',
        timeTickets: {},
        isLoading: false
    };

    componentDidMount = () => {
        this.toggleIsLoading();
        getData(myRegistration)
            .then(myRegistrationData => {
                const timeTickets = myRegistrationData.timeTickets;
                let countdownStartDateTime;
                Object.keys(timeTickets).map((item, index) => {
                    const currentTimeTicket = timeTickets[item];
                    this.setState({
                        timeTickets,
                        futureSemester: this.titleCase(
                            timeTickets.futureTermTitle
                        )
                    });
                    if (
                        currentTimeTicket.timeTicket &&
                        currentTimeTicket.termIndex == 2
                    ) {
                        const countdownStartDate =
                            currentTimeTicket.timeTicket.startDate;
                        countdownStartDateTime = new Date(
                            countdownStartDate
                        ).getTime();
                        this.setState({
                            countdownStartDate,
                            countdownStartDateTime,
                            currentTime: new Date().getTime()
                        });
                    }
                });

                if (countdownStartDateTime) {
                    this.calculateCountDownTime(countdownStartDateTime);
                    this.countDownInterval = setInterval(() => {
                        this.updateCountDownTime();
                    }, 1000);
                }

                this.toggleIsLoading();
            })
            .catch(err => {
                this.toggleIsLoading();
                // TODO: Need to handle the case where registration API fails
            });
    };

    toggleIsLoading = () => {
        this.setState({isLoading: !this.state.isLoading});
    };

    titleCase = str => {
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    };

    calculateCountDownTime = startTime => {
        const currentTime = new Date().getTime();
        const distanceToDate = startTime - currentTime;
        let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
            (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor(
            (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
        );
        let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
        this.setState({days, hours, minutes, seconds});
    };

    updateCountDownTime = () => {
        if (this.state.countdownStartDateTime) {
            let updatedSeconds = Number(this.state.seconds) - 1;
            if (updatedSeconds < 0) {
                updatedSeconds = 59;
                this.setState({seconds: updatedSeconds});
                let updatedMinutes = Number(this.state.minutes) - 1;
                if (updatedMinutes < 0) {
                    updatedMinutes = 59;
                    this.setState({minutes: updatedMinutes});
                    let updatedHours = Number(this.state.hours) - 1;
                    if (updatedHours < 0) {
                        updatedHours = 23;
                        this.setState({hours: updatedHours});
                        let updatedDays = this.state.days - 1;
                        if (updatedDays > 0) {
                            this.setState({days: updatedDays});
                        }
                    } else {
                        this.setState({hours: updatedHours});
                    }
                } else {
                    this.setState({minutes: updatedMinutes});
                }
            } else {
                this.setState({seconds: updatedSeconds});
            }
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.countDownInterval);
    };

    render() {
        return (
            <React.Fragment>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    {...this.props}
                    className="myOdu__myRegistration"
                >
                    {this.state.isLoading && (
                        <Skeleton height={98} variant="rectangular" />
                    )}
                    {!this.state.isLoading && (
                        <div className="wrapper">
                            {this.props.statusAlerts?.holds?.length > 0 && (
                                <div className="text-center mb-3">
                                    <Stack
                                        direction={{
                                            xs: 'column',
                                            sm: 'row',
                                            md: 'column',
                                            lg: 'row',
                                            xl: 'column',
                                            xxl: 'row',
                                            xxxl: 'row',
                                            xxxxl: 'row'
                                        }}
                                        sx={{
                                            alignItems: {xs: 'center'},
                                            justifyContent: {
                                                xs: 'center',
                                                lg: 'center',
                                                xxl: 'center',
                                                xxxl: 'center',
                                                xxxxl: 'center'
                                            }
                                        }}
                                    >
                                        <Typography
                                            variant="small"
                                            className="myOdu__textBadge cautionBadge"
                                            sx={{display: 'inline'}}
                                        >
                                            Action Required
                                        </Typography>
                                        <Typography
                                            variant="small"
                                            sx={{display: 'inline', ml: 1}}
                                        >
                                            You have{' '}
                                            <Link
                                                onClick={() =>
                                                    this.props.navigate(
                                                        '/statusAlerts'
                                                    )
                                                }
                                                underline="always"
                                                sx={{cursor: 'pointer'}}
                                            >
                                                {
                                                    this.props.statusAlerts
                                                        .holds.length
                                                }{' '}
                                                {this.props.statusAlerts.holds
                                                    .length > 1
                                                    ? 'holds'
                                                    : 'hold'}{' '}
                                                on your account
                                            </Link>
                                            .
                                        </Typography>
                                    </Stack>
                                </div>
                            )}
                            {Object.keys(this.state.timeTickets).length > 0 &&
                            this.state.countdownStartDate ? (
                                <>
                                    {this.state.countdownStartDateTime >=
                                        this.state.currentTime && (
                                        <Box
                                            className="myOdu__box"
                                            sx={{m: 0, textAlign: 'center'}}
                                        >
                                            <Stack
                                                sx={{mb: 2}}
                                                direction={{
                                                    xxl: 'row',
                                                    xl: 'column',
                                                    lg: 'row',
                                                    md: 'row',
                                                    sm: 'row',
                                                    xs: 'row'
                                                }}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <div
                                                    style={{ width: '50px', height: '30px' }}
                                                    className="ticketIcon me-xxl-1 me-xl-0 me-lg-1 me-md-1 me-sm-1 me-1"
                                                >
                                                    <img src="img/ticket.svg" alt="ticket" />
                                                </div>
                                                <Typography
                                                    variant="small"
                                                    component="h3"
                                                    sx={{
                                                        fontWeight: 600,
                                                        mb: 1,
                                                        textAlign: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    You may{' '}
                                                    <Link
                                                        target="_blank"
                                                        href="https://reg-prod.ec.odu.edu/StudentRegistrationSsb/ssb/registration"
                                                        underline="always"
                                                        sx={{cursor: 'pointer'}}
                                                    >
                                                        register
                                                    </Link>{' '}
                                                    for{' '} {this.state.futureSemester}{' '} in{' '}
                                                </Typography>
                                            </Stack>

                                            <Container>
                                                <Row>
                                                    <Col xs={3} className="ps-0" >
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="myOdu__border"
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="p"
                                                            >
                                                                { this.state .days }
                                                            </Typography>
                                                            <Typography
                                                                sx={theme => ({ [theme.breakpoints.only( 'xs' )]: { fontSize: '0.65rem' } })}
                                                                variant="smaller"
                                                                component="p"
                                                            >
                                                                Days
                                                            </Typography>
                                                        </Stack>
                                                    </Col>
                                                    <Col xs={3} className="ps-0" >
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="myOdu__border"
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="p"
                                                            >
                                                                { this.state .hours }
                                                            </Typography>
                                                            <Typography
                                                                sx={theme => ({ [theme.breakpoints.only( 'xs' )]: { fontSize: '0.65rem' } })}
                                                                variant="smaller"
                                                                component="p"
                                                            >
                                                                Hours
                                                            </Typography>
                                                        </Stack>
                                                    </Col>
                                                    <Col xs={3} className="ps-0" >
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="myOdu__border"
                                                        >
                                                            <Typography variant="h3" component="p" >
                                                                { this.state .minutes }
                                                            </Typography>
                                                            <Typography
                                                                sx={theme => ({ [theme.breakpoints.only( 'xs' )]: { fontSize: '0.65rem' } })}
                                                                variant="smaller"
                                                                component="p"
                                                            >
                                                                Minutes
                                                            </Typography>
                                                        </Stack>
                                                    </Col>
                                                    <Col xs={3} className="ps-0 pe-0" >
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="myOdu__border"
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="p"
                                                            >
                                                                { this.state .seconds }
                                                            </Typography>
                                                            <Typography
                                                                sx={theme => ({ [theme.breakpoints.only( 'xs' )]: { fontSize: '0.65rem' } })}
                                                                variant="smaller"
                                                                component="p"
                                                            >
                                                                Seconds
                                                            </Typography>
                                                        </Stack>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Box>
                                    )}

                                    {/* ELSE, ticket expired but registration still available */}

                                    {!(
                                        this.state.countdownStartDateTime >=
                                        this.state.currentTime
                                    ) && (
                                        <Box
                                            className="myOdu__box"
                                            variant="smaller"
                                            sx={{m: 0, textAlign: 'center'}}
                                        >
                                            <Stack
                                                direction={{
                                                    xxl: 'row',
                                                    xl: 'column',
                                                    lg: 'row',
                                                    md: 'row',
                                                    sm: 'row',
                                                    xs: 'row'
                                                }}
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <div
                                                    style={{
                                                        width: '50px',
                                                        height: '50px'
                                                    }}
                                                    className="me-xxl-1 me-xl-0 me-lg-1 me-md-1 me-sm-1 me-1"
                                                >
                                                    <img src="img/logos/registration.svg" alt="registration" />
                                                </div>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        component="h3"
                                                        sx={{fontWeight: 600}}
                                                    >
                                                        Registration is Open!
                                                    </Typography>
                                                    <Typography>
                                                        <Link
                                                            target="_blank"
                                                            href="https://reg-prod.ec.odu.edu/StudentRegistrationSsb/ssb/registration"
                                                            underline="always"
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            You may register for{' '}
                                                            { this.state .futureSemester }{' '}
                                                            classes
                                                        </Link>
                                                        .
                                                    </Typography>
                                                </div>
                                            </Stack>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <Typography variant="small" sx={{mb: 2}}>
                                    {' '}
                                    {this.state.futureSemester.includes('Fall')
                                        ? this.state.futureSemester.replace(
                                              'Fall',
                                              'Summer/Fall'
                                          )
                                        : this.state.futureSemester}{' '}
                                    open{' '}
                                    <Link
                                        target="_blank"
                                        href="https://reg-prod.ec.odu.edu/StudentRegistrationSsb/ssb/registration"
                                        underline="always"
                                        sx={{cursor: 'pointer'}}
                                    >
                                        registration
                                    </Link>{' '}
                                    began Saturday, March 30. No{' '}
                                    <Link
                                        target="_blank"
                                        href="https://www.odu.edu/academics/courses-registration/registration/time-ticketing"
                                        underline="always"
                                        sx={{cursor: 'pointer'}}
                                    >
                                        time ticket
                                    </Link>{' '}
                                    required.{' '}
                                </Typography>
                            )}
                            {/* [TO-DO Version 1.1] ELSE, registration expired
                                {!(this.state.countdownStartDateTime >= this.state.currentTime) && <Box className="myOdu__box" variant="smaller" sx={{ m: 0, textAlign: 'center' }}>
                                    <Stack direction={{ xxl: 'row', xl: 'column', lg: 'row', md: 'row', sm: 'row', xs: 'row' }} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                                        <div style={{ width: "50px", height: "50px" }} className="me-xxl-1 me-xl-0 me-lg-1 me-md-1 me-sm-1 me-1">
                                            <img src="img/logos/registration.svg" alt="registration" />
                                        </div>
                                        <div>
                                            <Typography variant="small" component="h3" sx={{ fontWeight: 600 }}>Registration is Open!</Typography>
                                            <Typography><Link target="_blank" href="https://xessb.odu.edu/StudentRegistrationSsb/ssb/registration" underline="always" sx={{ cursor: 'pointer' }}>You may register for {this.state.futureSemester} classes</Link>.</Typography>
                                        </div>
                                    </Stack>
                                </Box>}
                            </>
                            :
                            <Typography variant="small" sx={{ mb: 2 }}> {this.state.futureSemester.includes('Fall') ? this.state.c.replace('Fall', 'Summer/Fall') : this.state.futureSemester} open <Link target="_blank" href="https://xessb.odu.edu/StudentRegistrationSsb/ssb/registration" underline="always" sx={{ cursor: 'pointer' }}>registration</Link> began Saturday, March 30. No <Link target="_blank" href="https://www.odu.edu/academics/courses-registration/registration/time-ticketing" underline="always" sx={{ cursor: 'pointer' }}>time ticket</Link> required. </Typography>
                        }
                        {/* [TO-DO Version 1.1] ELSE, registration expired
                <Box className="myOdu__box" variant="smaller" sx={{ m: 0, textAlign: 'center' }} justifyItems={"center"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                    <Stack direction={{ xxl: 'row', xl: 'column', lg: 'row', md: 'row', sm: 'row', xs: 'row' }} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                        <div style={{ width: "50px", height: "30px", textAlign: "center" }} className="ticketIcon me-xxl-1 me-xl-0 me-lg-1 me-md-1 me-sm-1 me-1">
                            <img src="img/ticket.svg" alt="ticket" />
                        </div>
                        <div>
                            <Typography variant="small" component="h3" sx={{ fontWeight: 600 }}>Time Ticket for [SEMESTER]</Typography>
                            <Typography variant="small" component="p">
                                [SEMSTER] time tickets will be available approximately [Mon DD, YYYY].
                            </Typography>
                        </div>
                    </Stack>
                </Box> */}
                        </div>
                    )}
                </Widget>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        statusAlerts: state.myStatusAlertsReducer.statusAlerts
    };
};

export default connect(mapStateToProps)(WithRouter(MyRegistration));
