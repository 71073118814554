import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, {Component} from 'react';

class ActionButton extends Component {

    state = {
        anchor: null,
        isOpen: false,
    };

    //Θ(1) open anchor
    dropAnchor = (e) => {
        this.setState({anchor: e.currentTarget, isOpen: true})
    }
    
    //Θ(1) Close anchor 
    pullAnchor = () => {
        this.setState({anchor: null, isOpen: false})
    }

    render() {
        const {id, actionMenuItems} = this.props;
        return <React.Fragment>
            <Box
                id = {`${id}__box_actions`}
            >
                <Button 
                    id = {actionMenuItems.length === 1 ? actionMenuItems[0].id : `${id}__button_actions`}
                    variant='outlined' 
                    className='myOdu__button actionButton secondary'
                    {...(actionMenuItems.length > 1 ? {endIcon: <FontAwesomeIcon icon={faChevronRight} size='small' />} : {})}
                    onClick={actionMenuItems.length === 1 ? actionMenuItems[0].onClick : this.dropAnchor}
                    disabled = {this.props.disableActionButton}
                >
                    {actionMenuItems.length === 1 ? actionMenuItems[0].label : 'Actions'}
                </Button>
                <Menu
                    id = {`${id}__menu_actions`}
                    anchorEl={this.state.anchor}
                    open={this.state.isOpen}
                    onClose={this.pullAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {
                        actionMenuItems.map(menuItem => {
                            return <MenuItem
                                id = {menuItem.id}
                                onClick={() => {this.pullAnchor(); menuItem.onClick();}}
                                disabled = {this.props.disableActionButton}
                            >
                                {menuItem.label}
                            </MenuItem>
                        })
                    }
                </Menu>
            </Box>
        </React.Fragment>
    }

}

export default ActionButton;