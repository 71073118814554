import React, {Component} from 'react';
import PageHeader from '../PageHeader';
import { Box, Container } from '@mui/material';
import SAMFilter from '../SAMFilter';
import { connect } from 'react-redux';
import { getData, postData } from '../../../DataAccessLayer';
import { sam } from '../../../DataAccessLayer/services';
import { PageDetails } from '../config';
import StudentDetails from '../StudentDetails';
import { getColumns } from './utils';
import AssignAdvisorDialog from '../AssignAdvisorDialog';
import { toast } from 'react-toastify';
import { createSelectedStudentObject } from '../utils';
import UnassignConfirmation from '../UnassignConfirmation';

class AssignedStudents extends Component {
    state = {
        id: 'advisorsAssignedStudents',
        title: '',
        students: [],
        isLoading: false,
        activeAdvisor: {},
        activeAdvisorType: {},
        isSearchResult: false,
        columns: getColumns(),
        selectedStudents: [],
        isAssignAdvisor: false,
        actionMenuItems: [],
        isConfirmationPopup: false,
        errorMessage: ''
    }

    componentDidMount() {
        this.loadActionMenuItems();
        this.loadPageTitle();
    }

    //θ(1) Toggles isLoading
    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        })
    }

    //θ(1) Toggles isLoading
    toggleIsSearchResult = (isSearchResult = !this.state.isSearchResult) => {
        this.setState({
            isSearchResult
        })
    }

    //θ(1) Toggles isAssignAdvisor
    toggleIsAssignAdvisor = (isAssignAdvisor = !this.state.isAssignAdvisor) => {
        this.setState({
            isAssignAdvisor
        })
    }

    //θ(1) Toggles isAssignAdvisor
    toggleIsAssignAdvisor = (isAssignAdvisor = !this.state.isAssignAdvisor) => {
        this.setState({
            isAssignAdvisor
        })
    }

    //θ(1) Toggles isConfirmationPopup
    toggleIsConfirmationPopup = (isConfirmationPopup = !this.state.isConfirmationPopup) => {
        this.setState({
            isConfirmationPopup
        })
    }

    //θ(1) Sets action menu items
    loadActionMenuItems = () => {
        const {id} = this.state;

        const actionMenuItems = [
            {
                id: `${id}__menuItem_unassignStudent`,
                label: 'Unassign Student',
                onClick: this.toggleIsConfirmationPopup,
            },
            {
                id: `${id}__menuItem_assignAdvisor`,
                label: 'Assign New Advisor',
                onClick: this.toggleIsAssignAdvisor,
            }
        ];

        this.setState({
            actionMenuItems
        })
    }

    //θ(N) where N is the number of pages in PageDetails
    //Retrieve page title from pageDetails using pageid
    loadPageTitle = () => {
        const page = Object.values(PageDetails).find(page => page.id === this.state.id);
        this.setState({
            title: page.title
        })
    }

    //θ(1) Search students for seleted advisors
    //TODO add code to call search api
    searchStudents = (selectedValue) => {
        const {advisor, advisorType} = selectedValue;
        this.setState({
            activeAdvisor: advisor,
            activeAdvisorType: advisorType
        });
        this.toggleIsSearchResult(true);
        this.loadStudents(advisor, advisorType);
    }

    //θ(1) Loads students for selected department
    loadStudents = (advisor = this.state.activeAdvisor, advisorType = this.state.activeAdvisorType) => {
        this.toggleIsLoading(true);
        this.setState({
            students: []
        });
        
        getData(
            sam.assignedStudents(advisor.SIRDPCL_PIDM, advisorType.type),
            true
        )
        .then(students => {
            this.setState({
                students,
                errorMessage: ''
            })
        })
        .catch(err => {
            console.log(err);
            this.setState({
                errorMessage: 'We’re currently experiencing an issue retrieving student data. Please try again later.'
            })
        })
        .finally(_ => {
            this.toggleIsLoading(false);
        });
    }

    //θ(1) Sets the selected student in datagrid
    onStudentSelected = (selectedStudents) => {
        this.setState({
            selectedStudents
        })
    }

    //θ(1) Unassign selected students
    unassignStudents = () => {
        this.toggleIsLoading(true);
        
        const body = {
            studentPidms: this.state.selectedStudents
        };

        const studentMessage = this.state.selectedStudents.length > 1 ? 'Students' : 'Student'

        postData(
            sam.unassignStudents,
            body,
            true
        )
        .then(result => {
            toast.success(`${studentMessage} Unassigned`);
            this.toggleIsLoading(false);
            this.toggleIsConfirmationPopup(false)
            this.loadStudents();
        })
        .catch(err => {
            console.log(err);
            toast.error(`Unable to Unassign ${studentMessage}`);
            this.toggleIsLoading(false);
        })
    }

    //θ(1) assign new advisor to students
    onAssignAdvisor = (selectedValues) => {
        this.toggleIsLoading(true);
        const { advisor } = selectedValues;
        const students = createSelectedStudentObject(this.state.students, this.state.selectedStudents);

        const body = {
            students,
            newAdvisorPidm: advisor.SIRDPCL_PIDM,
            oldAdvisorPidm: this.state.activeAdvisor.SIRDPCL_PIDM
        };

        postData(
            sam.assignStudent,
            body,
            true
        )
        .then(result => {
            toast.success('New Advisor Assigned');
            this.toggleIsAssignAdvisor(false);
            this.toggleIsLoading(false);
            this.loadStudents();
        })
        .catch(err => {
            console.log(err);
            this.toggleIsLoading(false);
            toast.error('Unable to assign new advisor')
        })
    }

    render() {
        const id = this.state.id;
        const studentMessage = this.state.selectedStudents.length > 1 ? 'Students' : 'Student'
        return <React.Fragment>
            <div
                id={`${id}__div_pageWrapper`}
                className={`myOdu__${id}`}
                style={{}}
            >
                <Container id = {`${id}__container_pageWrapper`} className="myOdu__container secondaryPage">
                    <PageHeader 
                        id = {this.state.id}
                        title = {this.state.title} 
                        isSubPage
                    />

                    <Box id = {`${id}__box_wrapper`} className = {'wrapper'}>
                        <UnassignConfirmation
                            maxWidth="sm"
                            onClose={() => {this.toggleIsConfirmationPopup(false)}}
                            isOpen={this.state.isConfirmationPopup}
                            primaryButtonTitle="unassign"
                            onPrimaryClick={this.unassignStudents}
                            isPrimaryButtonDisabled={this.state.isLoading}
                            secondaryButtonTitle="cancel"
                            onSecondaryClick={() => {this.toggleIsConfirmationPopup(false)}}
                            isCloseable={true}
                            title={`Unassign Selected ${studentMessage}`}
                            message = {`Are you sure you want to unassign selected ${studentMessage.toLowerCase()}? This action cannot be undone.`}
                        />
                        <SAMFilter
                            id = {id}

                            isDepartmentCode
                            isAllDepartment
                            departmentsLength = {{xs: 12, xl: 5}}

                            isAdvisorName
                            isAdvisorRequired
                            advisorsLength = {{xs: 12, xl: 4}}

                            isAdvisorType
                            advisorTypesLength = {{xs: 12, xl: 3}}

                            primaryButtonText = {'Search'}
                            onPrimary = {this.searchStudents}
                            disablePrimaryButton = {this.state.isLoading}
                            primaryButtonLength = {{xs: 12, xl: 12}}

                            isClearFieldsButton = {this.state.isSearchResult}

                            widgetName = {this.state.title}
                        />
                        <AssignAdvisorDialog
                            isAssignAdvisor = {this.state.isAssignAdvisor}
                            toggleIsAssignAdvisor = {this.toggleIsAssignAdvisor}

                            selectedStudents = {this.state.selectedStudents}

                            onAssignAdvisor = {this.onAssignAdvisor}

                            disablePrimaryButton = {this.state.isLoading}
                        />
                        {
                            this.state.isSearchResult &&
                            <StudentDetails
                                id = {`${id}Details`}
                                students = {this.state.students}
                                columns = {this.state.columns}
                                onStudentSelected = {this.onStudentSelected}

                                actionMenuItems = {this.state.actionMenuItems}
                                disableActionButton = {this.state.selectedStudents.length === 0 || this.state.isLoading}

                                noStudentMessage = {this.state.errorMessage.length ? this.state.errorMessage : `There are no assigned students for ${this.state.activeAdvisor.NAME}.`}
                                message = {`Advisees for ${this.state.activeAdvisor.NAME}`}
                                isLoading = {this.state.isLoading}
                            />
                        }
                    </Box>
                </Container>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        departments: state.samReducer.departments,
        advisors: state.samReducer.advisors,
        advisorsByDept: state.samReducer.advisorsByDept,
        advisorTypes: state.samReducer.advisorTypes
    };
};

export default connect(mapStateToProps)(AssignedStudents);