import React, {Component} from 'react';
import Widget from '../common/Widget';
import TutoringTable from '../AdminPanel/Tutoring/TutoringTable';
import getColumns from '../AdminPanel/Tutoring/columns';
import {getData} from '../../DataAccessLayer';
import {tutoring} from '../../DataAccessLayer/services';
import {GridToolbar} from '@mui/x-data-grid-pro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-light-svg-icons';
import {IconButton, InputAdornment} from '@mui/material';
import {columnsToHideForMobile} from './config';
import {WithSmallScreen} from '../common/WithSmallScreen';

class CourseTutoring extends Component {
    state = {
        tutorings: [],
        isExpand: false
    };

    componentDidMount() {
        this.loadTutorings();
    }

    //O(1) Fetches all Tutorings
    loadTutorings() {
        getData(tutoring).then(data => {
            this.setState({
                tutorings: data
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    isExpand={this.state.isExpand}
                    {...this.props}
                    className="myOdu__courseTutoring"
                >
                    <TutoringTable
                        className="p-3"
                        rows={this.state.tutorings}
                        columns={getColumns()}
                        columnVisibilityModel={
                            this.props.isSmall
                                ? columnsToHideForMobile
                                : {actions: false}
                        }
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: {disableToolbarButton: true},
                                printOptions: {disableToolbarButton: true},
                                quickFilterProps: {
                                    sx: {m:2, width: '100%'},
                                    variant: 'outlined',
                                    size: 'small',
                                    placeholder: 'Search Course Tutoring'
                                }
                            }
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                    />
                </Widget>
            </React.Fragment>
        );
    }
}

export default WithSmallScreen(CourseTutoring);
