const myProgramLinks = [
    {
        id: 'checklists',
        link: '#',
        title: 'Checklists'
    },
    {
        id: 'monarchTransfermation',
        link: 'https://transfer2.odu.edu/#/',
        title: 'Monarch TRANSFERmation',
        target: "_blank",
        rel: "noreferrer",
        tooltipTitle: 'Opens in new window'
    },
    {
        id: 'academicTranscripts',
        link: 'https://ssb-prod.ec.odu.edu/PROD/bwskotrn.P_ViewTermTran',
        title: 'Academic Transcripts',
        target: "_blank",
        rel: "noreferrer",
        tooltipTitle: 'Opens in new window'
    },
];


export default myProgramLinks;