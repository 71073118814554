import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { Component } from "react";

class PortalDataGridPro extends Component {

    
    state = {
    };

    render() {
        return <React.Fragment>
            <DataGridPro
                className={this.props.className}
                id={this.props.id}
                density="compact"
                experimentalFeatures={{ ariaV7: true }} //for ADA
                rows={this.props.rows}
                columns={this.props.columns}
                initialState={this.props.initialState}
                pageSizeOptions={[10, 20, 50, 100]}
                onRowClick={this.props.handleClick}
                getRowId={this.props.getRowId}
                loading = {this.props.isLoading}
                slots={this.props.slots}
                columnVisibilityModel = {this.props.columnVisibilityModel}
                slotProps={this.props.slotProps}
                disableColumnFilter = {this.props.disableColumnFilter}
                disableColumnSelector = {this.props.disableColumnSelector}
                disableDensitySelector = {this.props.disableDensitySelector}
                pagination = {this.props.pagination}
                {...this.props}
            />
        </React.Fragment>
    }
}

export default PortalDataGridPro;