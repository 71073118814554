import React, {Component} from 'react';
import {Box, Link, Typography} from '@mui/material';
import PortalTooltip from './PortalTooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFaceSurprise} from '@fortawesome/pro-light-svg-icons';
class ApiErrorMessage extends Component {
    render() {
        const {widgetName} = this.props;
        const plainWidgetName = Array.isArray(widgetName)
            ? widgetName.join('')
            : widgetName;

        const errorMessage = this.props?.errorMessage || 'Oops! It looks like we can\'t get the data right now.';
        const reloadMessage = this.props?.reloadMessage ? ` ${this.props?.reloadMessage}` : '.'

        return (
            <Box id={this.props.id + "_box"} sx={{textAlign: 'center'}}>
                <FontAwesomeIcon id={this.props.id +"_icon_surpriseFace"}  icon={faFaceSurprise} size="lg" />&nbsp;
                <Typography id={this.props.id + "_text_errorText"} variant="small">
                    {errorMessage}&nbsp;
                </Typography>
                <PortalTooltip
                    id={this.props.id + "_tooltip_link"}
                    title={`Reload ${this.props.isPage ? 'page' : 'widget'}`}
                >
                    <Typography id={this.props.id + "_text_errorText"} variant="small">
                        Try loading &nbsp;
                    </Typography>
                    <Link
                        variant="small"
                        onClick={this.props.reload}
                        underline="always"
                        color="inherit"
                        id={'error_message_reload_link_' + plainWidgetName}
                        sx={{
                            cursor: 'pointer'
                        }}
                    >
                        {plainWidgetName}
                    </Link>
                    <Typography id={this.props.id + "_text_errorText"} variant="small">
                        &nbsp;again later{reloadMessage}
                    </Typography>
                </PortalTooltip>
            </Box>
        );
    }
}

export default ApiErrorMessage;
