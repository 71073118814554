import {Time} from '../../common/MomentTime';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentCaptions} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../../common/PortalTooltip';
import {GridActionsCellItem} from '@mui/x-data-grid-pro';
import {faCheckCircle as faCheckCircleOutlined} from '@fortawesome/pro-light-svg-icons';
import {faCheckCircle as faCheckCircleSolid} from '@fortawesome/pro-solid-svg-icons';

const getColumns = (actionFunction, id) => {
    return [
        {
            headerName: 'Checked?',
            width: 30,
            field: 'IS_REVIEWED',
            type: 'actions',
            getActions: event => [
                <GridActionsCellItem
                    icon={
                        <FontAwesomeIcon
                            id={''}
                            icon={
                                event.row.IS_REVIEWED
                                    ? faCheckCircleSolid
                                    : faCheckCircleOutlined
                            }
                        />
                    }
                    label="Mark as reviewed"
                    id={''}
                    onClick={() => actionFunction(event.row)}
                />
            ]
        },
        {
            field: 'Comments?',
            headerName: 'Comments?',
            width: 30,
            renderCell: (params) => {
                return (
                    (params.row.BUG_DETAIL ||
                        params.row.IDEA_DETAIL ||
                        params.row.CHANGE_DETAIL ||
                        params.row.OTHER_DETAIL) && (
                        <PortalTooltip title="This item has comments">
                            <FontAwesomeIcon icon={faCommentCaptions} />
                        </PortalTooltip>
                    )
                );
            }
        },
        {field: 'MIDAS', headerName: 'MIDAS', width: 90},
        {
            field: 'TIMESTAMP',
            headerName: 'Date/Time',
            width: 170,
            valueGetter: (params, row) =>
                Time.University(row.TIMESTAMP).format(
                    'M/DD/YYYY h:mm A'
                )
        },

        {field: 'PAGE_URL', headerName: 'Page Url'},
        {field: 'BUG_DETAIL', headerName: 'Bug Detail'},
        {field: 'IDEA_DETAIL', headerName: 'Idea Detail'},
        {field: 'CHANGE_DETAIL', headerName: 'Change Detail'},
        {field: 'OTHER_DETAIL', headerName: 'Other Detail'},
        {
            field: 'METADATA',
            headerName: 'Metadata',
            valueGetter: (params, row) => JSON.stringify(row.METADATA)
        },
        {field: 'RATING', headerName: 'Rating', width: 70}
    ];
};

export default getColumns;
