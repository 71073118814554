import { Breadcrumbs, Link, Typography } from '@mui/material';
import React, {Component} from 'react';
import { PageDetails } from './config';

class PageHeader extends Component {

    state = {

    }

    render() {
        const {id, title} = this.props;
        const paths = window.location.pathname.split('/').filter(path => path !== '');
        return <React.Fragment>
            <Typography id={`${id}__text_title`} component='h2'>{title}</Typography>

            <Breadcrumbs className="myOdu__breadcrumbs" id={`${id}__breadCrumbs`} aria-label="breadcrumb">
                <Link href="/" id = {`${id}__link_dashboard`}>
                    Dashboard
                </Link>
                {
                    paths.map((path, index, array) => {
                        const pageDetails = PageDetails[path]
                        if(index === array.length - 1) {
                            return <Typography id = {`${id}__text_${pageDetails.id}`}>
                                {pageDetails.title}
                            </Typography>
                        }

                        return <Link href={`/${pageDetails.url}`} id = {`${id}__link_${pageDetails.id}`}>
                            {pageDetails.title}
                        </Link>
                    })
                }
            </Breadcrumbs>
        </React.Fragment>
    }
}

export default PageHeader;