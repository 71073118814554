export const ResourceLinks = [
    {
        id: "resource_academicCalendar",
        title: "Academic Calendar",
        link: "https://www.odu.edu/academics/calendar",
    },  
    {
        id: "resource_careerServices",
        title: "Career Services",
        link: "https://www.odu.edu/career-leadership/services-resources",
    }, 
    {
        id: "resource_chq",
        title: "Criminal History Questionnaire",
        link: "https://odu.kualibuild.com/app/builder/#/app/613a305e3aef4e0ba1c52f43/run",
    }, 
    {
        id: "resource_financialAid",
        title: "Financial Aid",
        link: "https://www.odu.edu/finaidoffice/",
    }, 
    {
        id: "resource_financialAidPortal",
        title: "Financial Aid Portal",
        link: "https://studentssb-prod.ec.odu.edu/StudentSelfService/ssb/financialAid"
    }, 
    {
        id: "resource_graduateSchool",
        title: "Graduate School",
        link: "https://www.odu.edu/graduateschool"
    }, 
    {
        id: "resource_healthPromotionOffice",
        title: "Health Promotion Office",
        link: "https://www.odu.edu/recreation-wellness/health-wellness/health-promotion",
    }, 
    {
        id: "resource_militaryConnections",
        title: "Military Connection",
        link: "https://www.odu.edu/military-connection",
    }, 
    {
        id: "resource_monarchMoneyMatters",
        title: "Monarch Money Matters",
        link: "https://www.odu.edu/auxiliary-business-services/monarch-money-matters",
    }, 
    {
        id: "resource_jobPostings",
        title: "ODU Job Postings",
        link: "https://jobs.odu.edu/",
    },  
    {
        id: "resource_priorLearningAssessments",
        title: "Prior Learning Assessments",
        link: "https://ww1.odu.edu/academics/academic-records/evaluation-of-credit/prior-learning/",
    },
    {
        id: "resource_studentConduct",
        title: "Student Conduct & Academic Integrity",
        link: "https://www.odu.edu/student-conduct-academic-integrity",
    },
    {
        id: "resource_studentOpinionSurveys",
        title: "Student Opinion Surveys",
        link: "https://www.odu.edu/information-technology-services/student-opinion-survey/",
    },  
    {
        id: "resource_titleIX",
        title: "Title IX",
        link: "https://www.odu.edu/sees/sexual-assault-resources",
    }, 
    {
        id: "resource_tuitionEstimator",
        title: "Tuition and Cost Estimator",
        link: "https://itsapps.odu.edu/calculator/",
    },
    {
        id: "resource_tuitionPayment",
        title: "Tuition Payment",
        link: "https://ww1.odu.edu/tuition-aid/tuition/billing/payments/",
    },
];
export const EvhscResourceLinks = [
    {
        id: "resource_evhsc_duo",
        title: "VHS DUO 2-Factor Authentication Instructions",
        link: "https://myportal.evms.edu/informationtechnology/networkinformationcenternic/duotwo-factorauthentication/",
    },
    {
        id: "resource_evhsc_ethics",
        title: "VHS Ethics & Compliance Hotline",
        link: "https://app.convercent.com/en-us/LandingPage/683edfd1-4bee-e611-80d9-000d3ab1117e",
    },
    {
        id: "resource_evhsc_facilitiesOperations",
        title: "VHS Facilities Operations",
        link: "https://myportal.evms.edu/administrative_services/facilities/",
    },
    {
        id: "resource_evhsc_networkAcctManagement",
        title: "VHS Network Account Management",
        link: "https://myportal.evms.edu/informationtechnology/networkinformationcenternic/networkaccountmanagement/",
    },
    {
        id: "resource_evhsc_papercutSetup",
        title: "VHS PaperCut Setup",
        link: "http://studentprint.evms.net:9163/setup",
    },
    {
        id: "resource_evhsc_passwordReset",
        title: "VHS Password Reset",
        link: "https://passwordreset.evms.edu",
    },
];