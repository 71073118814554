import React, {Component} from 'react';
import PageHeader from '../PageHeader';
import {Box, Container} from '@mui/material';
import {PageDetails} from '../config';
import {getColumns} from './utils';
import StudentDetails from '../StudentDetails';
import {getData} from '../../../DataAccessLayer';
import {sam} from '../../../DataAccessLayer/services';
import {toast} from 'react-toastify';

class MissingAdvisorCode extends Component {
    state = {
        id: 'studentsWithMissingAdvisorCode',
        title: '',
        isLoading: true,
        students: [],
        columns: getColumns(),
        actionMenuItems: [],
        errorMessage: ''
    };

    componentDidMount() {
        this.loadPageTitle();
        this.loadActionMenuItems();
        this.loadStudents();
    }

    //θ(1) Toggles isLoading
    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        });
    };

    //θ(N) where N is the number of pages in PageDetails
    //Retrieve page title from pageDetails using pageid
    loadPageTitle = () => {
        const page = Object.values(PageDetails).find(
            page => page.id === this.state.id
        );
        this.setState({
            title: page.title
        });
    };

    //θ(1) Sets action menu items
    loadActionMenuItems = () => {
        const {id} = this.state;

        const actionMenuItems = [
            {
                id: `${id}__menuItem_refresh`,
                label: 'Refresh',
                onClick: this.loadStudents
            }
        ];

        this.setState({
            actionMenuItems
        });
    };

    //θ(1) Loads students with missing advisor code
    loadStudents = () => {
        this.toggleIsLoading(true);
        this.setState({
            students: []
        });
        getData(sam.missingAdvisorCode, true)
            .then(students => {
                this.setState({
                    students,
                    errorMessage: ''
                });
            })
            .catch(err => {
                this.setState({
                    errorMessage: 'We’re currently experiencing an issue retrieving student data. Please try again later.'
                })
                console.log(err);
            })
            .finally(_ => {
                this.toggleIsLoading(false);
            });
    };

    render() {
        const id = this.state.id;

        return (
            <React.Fragment>
                <div
                    id={`${id}__div_pageWrapper`}
                    className={`myOdu__${id}`}
                    style={{}}
                >
                    <Container
                        id={`${id}__container_pageWrapper`}
                        className="myOdu__container secondaryPage"
                    >
                        <PageHeader
                            id={this.state.id}
                            title={this.state.title}
                            isSubPage
                        />

                        <Box id={`${id}__box_wrapper`} className={'wrapper'}>
                            <StudentDetails
                                id={`${id}Details`}
                                students={this.state.students}
                                columns={this.state.columns}
                                actionMenuItems={this.state.actionMenuItems}
                                isHideCheckbox={true}
                                noStudentMessage={
                                    this.state.errorMessage.length ? this.state.errorMessage :
                                    "There are no students with missing advisor code."
                                }
                                isLoading={this.state.isLoading}
                            />
                        </Box>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default MissingAdvisorCode;
