import {Time} from '../../common/MomentTime';

const getColumns = actionFunction => {
    return [
        {field: 'MIDAS', headerName: 'Midas', flex: 1},
        {
            field: 'CREATED_AT',
            headerName: 'Created At',
            flex: 1,
            valueGetter: (params, row) =>
                Time.University(row.CREATED_AT).format(
                    'MM/DD/YYYY hh:mm:ss A'
                )
        },
        {
            field: 'ERROR',
            headerName: 'Error',
            flex: 1,
            valueGetter: (params, row) => JSON.stringify(row.ERROR)
        }
    ];
};

export default getColumns;
