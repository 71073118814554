import { Box, Breadcrumbs, Card, CardActionArea, Container, Grid, Link, Stack, Typography } from '@mui/material';
import React, {Component} from 'react';
import { Col, Row } from 'reactstrap';
import PageNavigationCard from '../common/PageNavigationCard';
import PageHeader from './PageHeader';
import { BreakpointBoxes } from '../common/BreakpointBoxes';
import { PageDetails } from './config';
import { connect } from 'react-redux';
import { isUserAdmin, isUserSAMPowerUp } from '../Layout/utils';

class SAM extends Component {

    state = {
        id: 'studentAdvisorManagement',
        pageDetails: {},
        samPages: []
    }

    componentDidMount() {
        this.loadCurrentPageDetails();
        this.loadSubPages();
    }

    //θ(N) where N is the number of pages
    //Search for current page details and set in state
    loadCurrentPageDetails = (pages = PageDetails) => {
        const pageDetails = Object.values(pages).find(page => page.id === this.state.id);
        this.setState({
            pageDetails
        })
    }

    //θ(N) where N is the number of pages
    //Get all subpages and set in state
    loadSubPages = (pages = PageDetails) => {
        const samPages = Object.values(pages).filter(page => {
            const isPage = page.id !== this.state.id

            if(!page.required)
                return isPage;

            const isRequired = page.required.some(checker => {
                switch (checker) {
                    case 'samPowerUserUp': {
                        return isUserSAMPowerUp(this.props.user) || isUserAdmin(this.props.user);
                    }
                }
            });

            return isPage && isRequired;
        });
        this.setState({
            samPages
        })
    }

    render() {
        const id = this.state.id;
        return <React.Fragment>
            <div
                id={`${id}__div_pageWrapper`}
                className={`myOdu__${id}`}
            >
                <Container id = {`${id}__container_pageWrapper`} className="myOdu__container secondaryPage">
                    <PageHeader 
                        id = {id}
                        title = {this.state.pageDetails?.title}
                    />

                    <Box id = {`${id}__box_wrapper`} className = {'wrapper'}>
                        <Stack
                            id={`${id}__row_samOptions`}
                            direction={{xs: 'column', lg: 'row'}}
                            spacing={2}
                        >
                            {
                                this.state.samPages.map(item => {
                                    return <PageNavigationCard
                                        url = {this.state.pageDetails.url}
                                        id = {id}
                                        subPage = {item}
                                    />
                                })
                            }
                        </Stack>
                    </Box>
                </Container>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
    };
};

export default connect(mapStateToProps)(SAM);