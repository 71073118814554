// FormSelect.jsx
import React from 'react';
import {
    Select,
    MenuItem,
    Typography,
    Grid,
    InputLabel,
    useMediaQuery,
    ListItemText,
    ListItemIcon,
    FormControl,
    Box,
    Autocomplete
} from '@mui/material';
import {Controller} from 'react-hook-form';
import _ from 'lodash';
import {FieldRequiredMark} from './FieldRequiredMark';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/pro-light-svg-icons';
import PortalAutoComplete from '../PortalAutocomplete';

export const FormAutoComplete = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    options,
    labelGridProps = {
        xs: 0,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    showChecks,
    placeholder,
    ...props
}) => {
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));

    return (
        <Grid container spacing={{xs: 0, sm: 1}} alignItems="center">
            <FormAutoCompleteUnwrapped
                id={id}
                label={label}
                error={error}
                helperText={helperText}
                errorText={errorText}
                required={required}
                options={options}
                labelGridProps={labelGridProps}
                inputGridProps={inputGridProps}
                showChecks={showChecks}
                placeholder={placeholder}
                {...props}
            />
        </Grid>
    );
};

//TODO: remove guts of FormDropDown with exception of
//outer grid then call this
export const FormAutoCompleteUnwrapped = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    options,
    labelGridProps = {
        xs: 0,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    showChecks,
    placeholder,
    textAlign = {xs: 'start', md: 'end', lg: 'end'},
    value,
    ...props
}) => {
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));

    return (
        <>
            {label && (
                <Grid
                    item
                    textAlign={textAlign}
                    {...labelGridProps}
                >
                    <Typography
                        id={`${id}__label`}
                        for={id}
                        className="myOdu__label"
                        component="label"
                    >
                        {label}{' '}
                        {required && <FieldRequiredMark label={label} />}
                    </Typography>
                    {!isSm && error && <p></p>}
                </Grid>
            )}
            <Grid item {...inputGridProps}>
                <FormControl size="small" fullWidth>
                    <PortalAutoComplete 
                        id = {id}
                        labelId={`${id}-select-label`}
                        options={options}
                        value = {value}
                        placeholder = {placeholder}
                        {...props}
                    />
                </FormControl>

                {error ? (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__error"
                    >
                        {errorText}
                    </Typography>
                ) : (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__helper"
                    >
                        {helperText}
                    </Typography>
                )}
            </Grid>
        </>
    );
};
