import { Autocomplete, Box, Button, Grid, Stack, TextField } from '@mui/material';
import React, {Component} from 'react';
import { FormDropDown } from '../common/Form/FormDropdown';
import PortalAutoComplete from '../common/PortalAutocomplete';
import { FormAutoComplete } from '../common/Form/FormAutoComplete';
import { connect } from 'react-redux';
import { saveAdvisors, saveAdvisorsByDept, saveAdvisorTypes, saveDepartments } from './actions';
import { AdvisorTypes, DefaultDepartment } from './config';
import { getData } from '../../DataAccessLayer';
import { sam } from '../../DataAccessLayer/services';
import { toast } from 'react-toastify';
import PortalAlert from '../common/PortalAlert';
import ApiErrorMessage from '../common/ApiErrorMessage';

class SAMFilter extends Component {

    state = {
        selectedDepartment: this.props.isAllDepartment ? DefaultDepartment : {},
        selectedAdvisor: {},
        selectedAdvisorType: AdvisorTypes[0],
        advisors: [],
        isErrorAdvisor: false,
        isErrorDepartment: false
    };

    //θ(1) toggles isErrorAdvisor
    toggleIsErrorAdvisor = (isErrorAdvisor = !this.state.isErrorAdvisor) => {
        this.setState({
            isErrorAdvisor
        })
    }

    //θ(1) toggles isErrorDepartment
    toggleIsErrorDepartment = (isErrorDepartment = !this.state.isErrorDepartment) => {
        this.setState({
            isErrorDepartment
        })
    }

    componentDidMount() {
        this.loadPageData();
    }

    //θ(1) Loads all required data for dropdowns
    loadPageData = () => {
        this.loadDepartmentCodes();
        this.loadAdvisors();
        this.loadAdvisorTypes();
    }

    //θ(1) Load Department Code
    loadDepartmentCodes = () => {
        //Return if departments already present
        if(this.props.departments.length > 0)
            return;
        
        getData(
            sam.allDepartments,
            true
        )
        .then(departments => {
            this.props.saveDepartments(departments);
            this.toggleIsErrorDepartment(false);
        })
        .catch(err => {
            console.log(err);
            this.toggleIsErrorDepartment(true);
        })
    }

    //θ(1) Load Advisor Details
    loadAdvisors = () => {
        
        //Return if advisors already present
        if(this.props.advisors.length > 0) {
            this.updateAdvisors();
            return;
        }
        
        //Reset previously set advsior details, as we will be fetching new details as per selected department
        this.resetAdvisors();
        getData(
            sam.allAdvisors,
            true
        )
        .then(advisors => {
            const advisorsByDept = this.createAdvisorObject(advisors);
            this.props.saveAdvisors(this.filterAdvisors(advisors));
            this.props.saveAdvisorsByDept(advisorsByDept);
            this.toggleIsErrorAdvisor(false);
        })
        .catch(err => {
            console.log(err);            
            this.toggleIsErrorAdvisor(this.props?.isAdvisorRequired ? true : false);
        })
        .finally(_ => {
            this.updateAdvisors();
        })
    }

    //θ(1) Load Advisor Types
    loadAdvisorTypes = () => {
        const advisorTypes = AdvisorTypes;
        this.props.saveAdvisorTypes(advisorTypes);
    }

    //θ(N) wher N is the number of advisors
    //Removes duplicate advisors based on PIDM
    filterAdvisors = (advisors = []) => {
        const set = new Set();
        advisors = advisors.filter(advisor => {
            if(set.has(advisor.SIRDPCL_PIDM))
                return false;
            
            set.add(advisor.SIRDPCL_PIDM);
            return true;
        });

        return advisors;
    }

    //θ(N) wher N is the number of advisors
    //Creates an object where key is department code and value is array of advisors of respective dept
    createAdvisorObject = (advisors) => {
        const advisorsByDept = advisors.reduce((prevAdvisors, advisor) => {
            prevAdvisors[advisor.SIRDPCL_DEPT_CODE] = [...(prevAdvisors[advisor.SIRDPCL_DEPT_CODE] || []), advisor]
            return prevAdvisors;
        }, {});
        
        return advisorsByDept;
    }

    //θ(1) When user moves out from department autocomplete and value in autocomplete is empty then we set it to default.
    //We cannot directly add it inside onChange while editing the autocomplete textfield and empty textfield is a valid input until user goes out of autocomplete
    onDepartmentBlur = () => {
        if(Object.keys(this.state.selectedDepartment).length === 0 && this.props.isAllDepartment) {
            this.setState({
                selectedDepartment: DefaultDepartment
            });
            this.updateAdvisors(DefaultDepartment);
        }
    }

    //θ(1) sets department value in state and loads advisors
    onSelectDepartment = (event, value = {}) => {
        if (value === null) {
            value = {}
        };

        this.setState({
            selectedDepartment: value
        });
        this.updateAdvisors(value);

        //We reset the advisor only when user changes the department
        //Advisor does not get reset when we clear departments as it then includes all advisors
        if(value?.STVDEPT_CODE !== DefaultDepartment.STVDEPT_CODE && Object.keys(value).length  !== 0)
            this.resetAdvisors();
    }

    //θ(1) sets advsior value in state
    onSelectAdvisor = (event, value = {}) => {
        
        if(value === null) {
            value = {};
        }
        this.setState({
            selectedAdvisor: value
        })
    }

    //θ(1) sets advisor type value in state
    onSelectAdvisorType = (event) => {
        this.setState({
            selectedAdvisorType: event.target.value
        })
    }

    //θ(1) Updates the list of advisors
    //If deptartment is {} then we add all advisors into the list
    //If deptartment is selected then the advisors of that deptartment are listed in select
    updateAdvisors = (dept = DefaultDepartment) => {
        let advisors = [];
        
        if(dept.STVDEPT_CODE !== DefaultDepartment.STVDEPT_CODE)
            advisors = this.props?.advisorsByDept[dept.STVDEPT_CODE] || [];
        else
            advisors = this.props.advisors;
    
        this.setState({
            advisors
        });
    }

    //θ(1) Resets advisors list in redux, reset selectedAdvisor & reset selected advisor type to All
    resetAdvisors = () => {
        this.setState({
            selectedAdvisor: {},
            selectedAdvisorType: AdvisorTypes[0]
        });
    }

    //θ(1) Disables primary button
    disablePrimaryButton = () => {
        const {isDepartmentRequired, isAdvisorRequired, isAdvisorTypeRequired} = this.props;

        if((isDepartmentRequired && Object.keys(this.state.selectedDepartment).length === 0)
            || (isAdvisorRequired && Object.keys(this.state.selectedAdvisor).length === 0)
            || (isAdvisorTypeRequired && Object.keys(this.state.selectedAdvisorType).length === 0)
            || this.props.disablePrimaryButton
        )
            return true;
        
        return false;
    }

    //θ(1) Create an object of selected department, advisor, advisorType and call the props onPrimary
    onPrimary = () => {
        const selectedValues = {
            department: this.state.selectedDepartment,
            advisor: this.state.selectedAdvisor,
            advisorType: this.state.selectedAdvisorType
        }

        this.props.onPrimary(selectedValues);
    }

    //θ(1) Resets all selected fields
    onClear = () => {
        const selectedDepartment = this.props.isAllDepartment ? DefaultDepartment : {};
        const selectedAdvisor = {};
        const selectedAdvisorType = AdvisorTypes[0];

        this.setState({
            selectedDepartment,
            selectedAdvisor,
            selectedAdvisorType
        })
    }

    render() {
        const id = this.props.id;

        const {isDepartmentCode, departments, disableDepartment, isAllDepartment} = this.props;
        const {isAdvisorName, disableAdvisorName, isAdvisorRequired} = this.props;
        const {isAdvisorType, disableAdvisorType, isAdvisorTypeRequired} = this.props;
        
        return <React.Fragment>
            <Stack 
                direction={'column'}
                gap = {2}
            >
                {
                    this.state.isErrorAdvisor &&
                    <PortalAlert id={"statusAlerts__portalAlert"} severity="error" type="statusAlert">
                        <ApiErrorMessage
                            id={"statusAlerts__apiErrorMessage"}
                            reload={this.loadPageData}
                            widgetName={this.props.widgetName}
                            isPage={true}
                        />
                    </PortalAlert>
                }
                {
                    this.state.isErrorDepartment && !this.state.isErrorAdvisor &&
                    <PortalAlert id={"statusAlerts__portalAlert"} severity="error" type="statusAlert">
                        <ApiErrorMessage
                            id={"statusAlerts__apiErrorMessage"}
                            reload={this.loadPageData}
                            errorMessage = {'Oops. It looks like we cant get the departments right now.'}
                            reloadMessage = {this.props.isDepartmentRequired ? '' : 'or try without departments.'}
                            widgetName={this.props.widgetName}
                            isPage={true}
                        />
                    </PortalAlert>
                }
                {
                    <Grid container
                        direction={{xs: 'column', sm: 'column', xl: 'row'}}
                        spacing={2}
                        sx={{
                            justifyContent: 'center'
                        }}
                    >
                        {
                            isDepartmentCode &&
                            <Grid item {...this.props.departmentsLength}>
                                <FormAutoComplete
                                    id = {`${id}__select_departmentCode`}
                                    clearIcon = {true}
                                    options={isAllDepartment ? [DefaultDepartment, ...departments] : departments}
                                    freeSolo
                                    value={this.state.selectedDepartment}
                                    getOptionLabel={(option) => Object.keys(option).length && ((option?.STVDEPT_DESC && option.STVDEPT_DESC + ' - ') + option.STVDEPT_CODE) || ''}
                                    onChange = {this.onSelectDepartment}
                                    size='small'
                                    sx={{width: '100%'}}
                                    label={'Department Code'}
                                    inputGridProps={{xs: 12}}
                                    labelGridProps={{xs: 12}}
                                    textAlign = {{xs: 'start'}}
                                    disabled = {disableDepartment || this.state.isErrorDepartment || this.state.isErrorAdvisor}
                                    onBlur = {this.onDepartmentBlur}
                                    required={this.props.isDepartmentRequired}
                                />
                            </Grid>
                        }
                        {
                            isAdvisorName &&
                            <Grid item {...this.props.advisorsLength}>
                                <FormAutoComplete
                                    id = {`${id}__select_advisorName`}
                                    clearIcon = {true}
                                    options={this.state.advisors}
                                    freeSolo
                                    value={this.state.selectedAdvisor}
                                    getOptionLabel={(option) => Object.keys(option).length && option.NAME || ''}
                                    onChange = {this.onSelectAdvisor}
                                    placeholder={'Choose an Advisor'}
                                    size='small'
                                    sx={{width: '100%'}}
                                    label={'Advisor Name'}
                                    inputGridProps={{xs: 12}}
                                    labelGridProps={{xs: 12}}
                                    textAlign = {{xs: 'start'}}
                                    required={isAdvisorRequired}
                                    disabled = {disableAdvisorName || this.state.isErrorAdvisor}
                                />
                            </Grid>
                        }
                        {
                            isAdvisorType &&
                            <Grid item {...this.props.advisorTypesLength}>
                                <FormDropDown
                                    id = {`${id}__select_advisorType`}
                                    name = 'selectedAdvisorType'
                                    options={AdvisorTypes?.map(
                                        (type, index) => ({
                                            value: type,
                                            label: type.label
                                        })
                                    )}
                                    value={this.state.selectedAdvisorType}
                                    onChange = {this.onSelectAdvisorType}
                                    label = {'Advisor Type'}
                                    ariaLabel={'advisor type dropdown'}
                                    labelGridProps = {{
                                        xs: 0
                                    }}
                                    inputGridProps = {{
                                        xs: 12
                                    }}
                                    disabled = {Object.keys(this.state.selectedAdvisor).length === 0}
                                    required={isAdvisorTypeRequired}
                                />
                            </Grid>
                        }
                        <Grid container item {...this.props.primaryButtonLength} justifyContent={'flex-end'} gap={1} direction={{xs: 'column-reverse', xl: 'row'}}> 
                            {
                                this.props.isClearFieldsButton &&
                                <Grid item>
                                    <Button
                                        id = {`${id}__button_clearFields`}
                                        className='myOdu__button secondary'
                                        variant='outlined'
                                        onClick={this.onClear}
                                        fullWidth
                                    >
                                        {'Clear'}
                                    </Button>
                                </Grid>
                            }
                            <Grid item
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    id = {`${id}__button`}
                                    className='myOdu__button primary'
                                    variant='outlined'
                                    onClick={this.onPrimary}
                                    disabled={this.disablePrimaryButton()}
                                    fullWidth
                                >
                                    {this.props.primaryButtonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Stack>
        </React.Fragment>
    }

}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        departments: state.samReducer.departments,
        advisors: state.samReducer.advisors,
        advisorsByDept: state.samReducer.advisorsByDept,
        advisorTypes: state.samReducer.advisorTypes
    };
};

const mapDispatchToProps = dispatch => ({
    saveDepartments: departments => dispatch(saveDepartments(departments)),
    saveAdvisors: advisors => dispatch(saveAdvisors(advisors)),
    saveAdvisorsByDept: advisorsByDept => dispatch(saveAdvisorsByDept(advisorsByDept)),
    saveAdvisorTypes: advisorTypes => dispatch(saveAdvisorTypes(advisorTypes))
});


export default connect(mapStateToProps, mapDispatchToProps)(SAMFilter);